<template>
  <center>
    <table
      align="center"
      border="0"
      cellpadding="0"
      cellspacing="0"
      height="100%"
      width="100%"
      id="bodyTable"
      style="line-height: 16px !important"
    >
      <tr>
        <td align="center" valign="top" id="bodyCell">
          <!-- BEGIN TEMPLATE // -->
          <!--[if (gte mso 9)|(IE)]>
                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                        <tr>
                        <td align="center" valign="top" width="600" style="width:600px;">
                        <![endif]-->
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            width="100%"
            class="templateContainer"
          >
            <tr>
              <td valign="top" id="templatePreheader"></td>
            </tr>
            <tr>
              <td valign="top" id="templateHeader">
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  width="100%"
                  class="mcnTextBlock"
                  style="min-width: 100%"
                >
                  <tbody class="mcnTextBlockOuter">
                    <tr>
                      <td
                        valign="top"
                        class="mcnTextBlockInner"
                        style="padding-top: 8px"
                      >
                        <!--[if mso]>
				<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
				<tr>
				<![endif]-->

                        <!--[if mso]>
				<td valign="top" width="600" style="width:600px;">
				<![endif]-->
                        <table
                          align="left"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          style="max-width: 100%; min-width: 100%"
                          width="100%"
                          class="mcnTextContentContainer"
                        >
                          <tbody>
                            <tr>
                              <td
                                valign="top"
                                class="mcnTextContent"
                                style="padding: 0px 6px 3px; line-height: 100%"
                              >
                                <div style="text-align: center">
                                  <span
                                    ><img
                                      data-file-id="5182366"
                                      height="45"
                                      src="https://tuyensinh.luongthevinh.com.vn/img/logo.6aa5b6c5.svg"
                                      style="
                                        border: 0px initial;
                                        width: 56px;
                                        height: 45px;
                                        margin: 0px;
                                      "
                                      width="56"
                                  /></span>
                                  <br />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <!--[if mso]>
				</td>
				<![endif]-->

                        <!--[if mso]>
				</tr>
				</table>
				<![endif]-->
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  width="100%"
                  class="mcnTextBlock"
                  style="min-width: 100%"
                >
                  <tbody class="mcnTextBlockOuter">
                    <tr>
                      <td
                        valign="top"
                        class="mcnTextBlockInner"
                        style="padding-top: 8px"
                      >
                        <!--[if mso]>
				<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
				<tr>
				<![endif]-->

                        <!--[if mso]>
				<td valign="top" width="600" style="width:600px;">
				<![endif]-->
                        <table
                          align="left"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          style="max-width: 100%; min-width: 100%"
                          width="100%"
                          class="mcnTextContentContainer"
                        >
                          <tbody>
                            <tr>
                              <td
                                valign="top"
                                class="mcnTextContent"
                                style="padding: 0px 6px 3px; line-height: 100%"
                              >
                                <div style="text-align: center">
                                  <span style="font-size: 14px"
                                    ><strong
                                      ><span
                                        ><span
                                          style="
                                            font-family: roboto, helvetica neue,
                                              helvetica, arial, sans-serif;
                                          "
                                          >TRƯỜNG&nbsp;THCS &amp; THPT</span
                                        ></span
                                      ></strong
                                    ></span
                                  >
                                  <br />
                                  <span style="font-size: 14px"
                                    ><font
                                      face="roboto, helvetica neue, helvetica, arial, sans-serif"
                                      ><strong>LƯƠNG THẾ VINH</strong></font
                                    ></span
                                  >
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <!--[if mso]>
				</td>
				<![endif]-->

                        <!--[if mso]>
				</tr>
				</table>
				<![endif]-->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td valign="top" id="templateBody">
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  width="100%"
                  class="mcnTextBlock"
                  style="min-width: 100%"
                >
                  <tbody class="mcnTextBlockOuter">
                    <tr>
                      <td
                        valign="top"
                        class="mcnTextBlockInner"
                        style="padding-top: 8px"
                      >
                        <!--[if mso]>
				<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
				<tr>
				<![endif]-->

                        <!--[if mso]>
				<td valign="top" width="600" style="width:600px;">
				<![endif]-->
                        <table
                          align="left"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          style="max-width: 100%; min-width: 100%"
                          width="100%"
                          class="mcnTextContentContainer"
                        >
                          <tbody>
                            <tr>
                              <td
                                valign="top"
                                class="mcnTextContent"
                                style="
                                  padding-top: 0;
                                  padding-right: 18px;
                                  padding-bottom: 9px;
                                  padding-left: 18px;
                                "
                              >
                                <div style="text-align: center">
                                  <br />
                                  <span style="font-size: 14px"
                                    ><span style="color: #757575"
                                      ><font
                                        face="roboto, helvetica neue, helvetica, arial, sans-serif"
                                        ><strong>MÃ HỒ SƠ</strong></font
                                      ></span
                                    ></span
                                  ><br />

                                  <span
                                    style="font-size: 14px; padding-top: 8px"
                                    ><font
                                      face="roboto, helvetica neue, helvetica, arial, sans-serif"
                                      >Hồ sơ tuyển sinh năm 2024 - 2025</font
                                    ></span
                                  ><br />
                                  <br />
                                  <span style="font-size: 24px"
                                    ><font
                                      face="roboto, helvetica neue, helvetica, arial, sans-serif"
                                      ><strong>{{
                                        activeCode.code || "---"
                                      }}</strong></font
                                    ></span
                                  ><br />
                                  <br />
                                  <span
                                    style="
                                      font-size: 16px;
                                      padding-top: 8px;
                                      font-weight: 500;
                                    "
                                    ><font
                                      face="roboto, helvetica neue, helvetica, arial, sans-serif"
                                      >{{
                                        activeCode.grade
                                          ? activeCode.grade.toUpperCase()
                                          : "---"
                                      }}</font
                                    ></span
                                  ><br /><br />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <!--[if mso]>
				</td>
				<![endif]-->

                        <!--[if mso]>
				</tr>
				</table>
				<![endif]-->
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  width="100%"
                  class="mcnTextBlock"
                  style="min-width: 100%"
                >
                  <tbody class="mcnTextBlockOuter">
                    <tr>
                      <td
                        valign="top"
                        class="mcnTextBlockInner"
                        style="padding-top: 8px"
                      >
                        <!--[if mso]>
				<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
				<tr>
				<![endif]-->

                        <!--[if mso]>
				<td valign="top" width="300" style="width:300px;">
				<![endif]-->
                        <table
                          align="left"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          width="50%"
                          class="mcnTextContentContainer"
                        >
                          <tbody>
                            <tr>
                              <td
                                valign="top"
                                class="mcnTextContent"
                                style="
                                  padding-top: 0;
                                  padding-left: 18px;
                                  padding-bottom: 9px;
                                  padding-right: 18px;
                                "
                              >
                                <div style="text-align: center">
                                  <span style="font-size: 11px"
                                    ><font
                                      face="roboto, helvetica neue, helvetica, arial, sans-serif"
                                      >Người xuất mã</font
                                    ></span
                                  ><br />
                                  <span
                                    style="font-size: 11px; padding-top: 16px"
                                    ><font
                                      face="roboto, helvetica neue, helvetica, arial, sans-serif"
                                      ><strong>{{
                                        activeCode.createdBy || "---"
                                      }}</strong></font
                                    ></span
                                  >
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <!--[if mso]>
				</td>
				<![endif]-->

                        <!--[if mso]>
				<td valign="top" width="300" style="width:300px;">
				<![endif]-->
                        <table
                          align="left"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          width="50%"
                          class="mcnTextContentContainer"
                        >
                          <tbody>
                            <tr>
                              <td
                                valign="top"
                                class="mcnTextContent"
                                style="
                                  padding-top: 0;
                                  padding-left: 18px;
                                  padding-bottom: 9px;
                                  padding-right: 18px;
                                "
                              >
                                <div style="text-align: center">
                                  <span style="font-size: 11px"
                                    ><font
                                      face="roboto, helvetica neue, helvetica, arial, sans-serif"
                                      >Ngày xuất mã</font ></span ><br />
                                  <span
                                    style="font-size: 11px; padding-top: 16px"
                                    ><font
                                      face="roboto, helvetica neue, helvetica, arial, sans-serif"
                                      ><strong>{{
                                        getCreatedAtDate
                                      }}</strong></font
                                    ></span
                                  ><br />
                                  <span
                                    style="font-size: 11px; padding-top: 16px"
                                    ><font
                                      face="roboto, helvetica neue, helvetica, arial, sans-serif"
                                      ><strong>{{
                                        getCreatedAtTime
                                      }}</strong></font
                                    ></span
                                  >
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <!--[if mso]>
				</td>
				<![endif]-->

                        <!--[if mso]>
				</tr>
				</table>
				<![endif]-->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td valign="top" id="templateFooter"></td>
            </tr>
          </table>

          <!--[if (gte mso 9)|(IE)]>
                        </td>
                        </tr>
                        </table>
                        <![endif]-->
          <!-- // END TEMPLATE -->
        </td>
      </tr>
    </table>
  </center>
</template>

<script>
import moment from "moment";
export default {
  props: {
    activeCode: Object,
  },
  computed: {
    getCreatedAtDate() {
      if (this.activeCode.createdAt)
        return moment(this.activeCode.createdAt).format("DD/MM/YYYY");
      return "---";
    },
    getCreatedAtTime() {
      if (this.activeCode.createdAt)
        return moment(this.activeCode.createdAt).format("HH:mm:ss");
      return "---";
    },
  },
};
</script>
<style>
@import "./PrintActiveCode.css";
</style>
