var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1200px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ConfirmDialog'),_c('v-card',[_c('v-card-title',{staticClass:"admin white--text text-uppercase dialog-title"},[_vm._v(" Xác nhận gửi tin cho phụ huynh "),_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","icon":""}},[_c('v-icon',{staticClass:"mr-n1",attrs:{"disabled":_vm.loading},on:{"click":_vm.cancel}},[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-6"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Tiêu đề *","persistent-hint":"","required":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"dense":"","outlined":"","label":"Nội dung ngắn","persistent-hint":"","required":"","hint":"Hiển thị trên tin nhắn ngoài màn hình chờ"},model:{value:(_vm.shortContent),callback:function ($$v) {_vm.shortContent=$$v},expression:"shortContent"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"dense":"","outlined":"","label":"Nội dung","required":"","hint":"Giữ nguyên 'studentName', 'sbd', 'ranking'"},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":[
                {
                  title: 'Màn hình thông báo',
                  value: '/'
                },
                {
                  title: 'Màn hình danh sách hồ sơ',
                  value: '/cv'
                }
              ],"item-text":"title","item-value":"value","label":"Link","required":"","outlined":"","dense":"","hide-details":"","hint":"Đường dẫn khi nhấn vào thông báo trong app"},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}})],1)],1)],1),_c('v-data-table',{attrs:{"item-key":"username","headers":[
          {text: 'Tài khoản', value: 'username'},
          {text: 'SBD', value: 'sbd'},
          {text: 'Tên học sinh', value: 'studentName'},
          {text: 'Xếp hạng (áp dụng với DỰ KHUYẾT)', value: 'ranking'}
        ],"loading":_vm.loading,"items":_vm.importedDocuments,"items-per-page":10,"disable-sort":_vm.$vuetify.breakpoint.smAndDown,"footer-props":{'items-per-page-text': 'Số Hồ Sơ một trang'}}}),_c('v-row',[(_vm.importState.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.importState)}})]):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"pa-6"},[_c('v-btn',{attrs:{"color":"admin","width":"118","loading":_vm.loading,"large":"","outlined":""},on:{"click":_vm.cancel}},[_vm._v("Hủy")]),_c('v-spacer'),_c('v-btn',{staticClass:"white--text elevation-0",attrs:{"color":"admin","loading":_vm.loading,"width":"118","large":""},on:{"click":_vm.confirm}},[_vm._v("Xác nhận")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }