<template>
  <v-dialog v-model="dialog" max-width="407px" persistent>
    <v-card>
      <v-card-title class="d-flex justify-center"
        ><div class="text-center black--text">Đăng ký nhận thông tin</div>
      </v-card-title>
      <v-card-text class="px-8 pb-0">
        <div
          class="text-body-2 text-justify mb-4 black--text"
          v-html="
            `Cha mẹ học sinh vui lòng để lại số điện thoại, nhà trường sẽ gửi tin nhắn SMS đến cha mẹ học sinh khi bắt đầu phát hành hồ sơ đăng ký.<br>Xin cảm ơn cha mẹ học sinh đã luôn tin tưởng và quan tâm hệ thống giáo dục Lương Thế Vinh!`
          "
        ></div>
        <v-form ref="form">
          <div class="text-subtitle-1 black--text">Số điện thoại <span style="color: red">*</span></div>
          <v-text-field
            placeholder="(Ví dụ: 097372xxxx)"
            name="login"
            type="tel"
            color="primary"
            v-mask="'##########'"
            v-model="phone"
            @keyup.enter="submit"
            :rules="[$rules.required, $rules.phone]"
            outlined
            validate-on-blur
          />
        </v-form>
      </v-card-text>
      <div class="px-8 pt-0 pb-6 d-flex flex-column justify-center">
        <v-btn
          depressed
          x-large
          color="primary"
          class="white--text text-subtitle-1 mt-4 text-none"
          style="width: 100%"
          :loading="loading"
          @click="submit"
        >
          Xác nhận
        </v-btn>
        <v-btn
          depressed
          x-large
          text
          color="primary"
          class="white--text text-subtitle-1 mt-2 text-none"
          style="width: 100%"
          @click="close"
        >
          Đóng
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import alert from '@/plugins/alert'
export default {
  data() {
    return {
      phone: '',
      dialog: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters('layout', ['phoneRegisterDialog'])
  },
  methods: {
    ...mapActions('layout', ['setPhoneRegisterDialog']),
    ...mapActions('phoneRegister', ['createPhoneRegister']),
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true
        try {
          await this.createPhoneRegister({phone: this.phone})
          this.$refs.form.reset()
          this.loading = false
          this.setPhoneRegisterDialog(false)
          alert.success('Đã đăng ký thành công')
        } catch (error) {
          this.loading = false
          if (error == 'duplicate') alert.error('Số điện thoại đã được đăng ký')
          else alert.error('Lỗi mạng. Vui lòng kiểm tra lại')
        }
      }
    },
    close() {
      this.setPhoneRegisterDialog(false)
    },
  },
  watch: {
    phoneRegisterDialog: {
      handler(val) {
        this.dialog = val
      }
    }
  }
}
</script>
