import {PhoneRegister} from '@/plugins/api'
export default {
  namespaced: true,
  state: {},
  actions: {
    async createPhoneRegister(_, params) {
      return await PhoneRegister.create(params)
    }
  },
  mutations: {},
  getters: {}
}