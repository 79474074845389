<template>
  <div>
    <div class="d-flex justify-end align-center mb-6">
      <v-menu offset-y>
        <template v-slot:activator="{on, attrs}">
          <v-btn color="primary" outlined v-bind="attrs" v-on="on" class="text-none">
            <v-icon left>mdi-tray-arrow-up</v-icon>
            Upload thông báo tuyển sinh
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in listAction" :key="index">
            <v-list-item-title v-if="index === 0">
              <JsonExcel
                :data="[{code: '', sbd: '', room: ''}]"
                :fields="example_json_fields"
                type="xls"
                worksheet="Hồ sơ"
                name="danh-sach-sbd-k6.xls"
              >
                {{ item.title }}
              </JsonExcel>
            </v-list-item-title>
            <v-list-item-title v-else @click="() => item.callback()">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <input
      ref="uploader"
      type="file"
      class="d-none"
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      @change="onFileChanged"
      v-show="false"
    />
    <ConfirmExcelResult
      :state="confirmResultDialog"
      :importedDocuments="importedDocuments"
      @closeDialog="closeConfirmDialog"
      @onConfirm="val => $emit('onConfirm', val)"
    />
  </div>
</template>
<script>
const schema = {
  'Tài khoản kích hoạt': {
    prop: 'username',
    type: String
  },
  'Số báo danh': {
    prop: 'sbd',
    type: String
  },
  'Tên học sinh': {
    prop: 'studentName',
    type: String
  },
  'Xếp hạng (áp dụng với DỰ KHUYẾT)': {
    prop: 'ranking',
    type: String
  },
}
import readXlsxFile from 'read-excel-file'
import JsonExcel from 'vue-json-excel'
import ConfirmExcelResult from './ResultExcelConfirm.vue'
export default {
  components: {
    JsonExcel,
    ConfirmExcelResult
  },
  data() {
    return {
      listAction: [
        {title: 'Xuất Excel mẫu', callback: () => this.downloadTemplate()},
        {title: 'Upload Excel', callback: () => this.uploadFile()}
      ],
      example_json_fields: {
        'Tài khoản kích hoạt': 'username',
        'Số báo danh': 'sbd',
        'Tên học sinh': 'studentName',
        'Xếp hạng (áp dụng với DỰ KHUYẾT)': 'ranking',
      },
      exampleData: [{username: '', sbd: '', studentName: '', ranking: ''}],
      importedDocuments: [],
      confirmResultDialog: false
    }
  },
  methods: {
    uploadFile() {
      this.$refs.uploader.click()
    },
    async onFileChanged(e) {
      this.$loading.active = true
      const selectedFile = e.target.files[0]
      let readFileResult
      try {
        readFileResult = await readXlsxFile(selectedFile, {schema})
      } catch (error) {
        this.$alert.error('File không có định dạng .xlsx hoặc có dữ liệu không hợp lệ (tham khảo mẫu file)')
        this.$loading.active = false
        return
      }
      this.$alert.success('Đã đọc File thành công!')
      if (readFileResult.errors.length !== 0) {
        const error = readFileResult.errors[0]
        this.$alert.error(`Lỗi Dòng ${error.row} - Cột ${error.column} - ${error.value}: ${error.error}`)
        this.$loading.active = false
        return
      } else {
        const results = readFileResult.rows
        this.importedDocuments = results
        console.log(this.importedDocuments)
        this.confirmResultDialog = true
      }
      this.$loading.active = false
    },
    closeConfirmDialog() {
      this.isSelecting
      this.confirmResultDialog = false
      this.$refs.uploader.value = null
    }
  }
}
</script>
