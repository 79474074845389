<template>
  <v-card width="100%" class="elevation-0">
    <v-divider class="py-2" v-if="$vuetify.breakpoint.smAndDown"></v-divider>
    <v-card-title class="card-title mb-2">Đăng ký tổ hợp môn học tự chọn</v-card-title>
    <v-card-text class="pa-0">
      <v-form v-model="isValid" ref="expectationForm" v-bind="this.$attrs" style="width: 100%">
        <div class="section-label py-6">
          Đăng ký tổ hợp môn học lựa chọn (Học sinh chọn 5 môn học từ 3 nhóm môn học dưới dây, mỗi nhóm chọn ít nhất 1
          môn học)
        </div>
        <div @click="openLinkToMoet" class="primary--text cursor-pointer mb-2">
          Tham khảo chương trình giáo dục phổ thông 2018
        </div>
        <GroupExpectationForm
          :isEditable="isEditable"
          :item="ltvExamResult.groupExpectation1"
          :department="document.department"
          ref="expectation-form-1"
          :index="1"
        />
        <hr class="dashed" :class="{'my-6': isEditable, 'mt-4': isNotEditable}" />
        <GroupExpectationForm
          :isEditable="isEditable"
          :item="ltvExamResult.groupExpectation2"
          :department="document.department"
          ref="expectation-form-2"
          :index="2"
          :class="{'mt-6 mb-4': isNotEditable}"
        />
      </v-form>
    </v-card-text>
    <hr class="dashed" />
    <v-card-actions class="d-flex justify-end pt-8 px-0">
      <v-btn
        :class="{'px-6': $vuetify.breakpoint.mdAndUp}"
        class="py-3 mr-6 text-none"
        color="primary"
        v-if="isEditable"
        @click="saveDraft"
        outlined
        large
      >
        <v-icon> mdi-content-save </v-icon>
        <span class="ml-2">Lưu</span>
      </v-btn>
      <v-btn
        :class="{'px-6': $vuetify.breakpoint.mdAndUp}"
        class="py-3 mr-6 text-none"
        color="primary"
        v-if="isUpdatable && !isEditable"
        @click="onEdit"
        outlined
        large
      >
        <span>Chỉnh sửa</span>
      </v-btn>
      <v-btn class="px-6 py-3 text-none elevation-0" color="primary" v-if="isAdminPreview" @click="nextStep" large>
        <span>Tiếp theo</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import moment from 'moment'
import GroupExpectationForm from './Grade10/GroupExpectationForm.vue'
export default {
  components: {
    GroupExpectationForm
  },
  props: {
    document: Object,
    documentStep: Number,
    systemTime: Object,
    isAdminPreview: Boolean,
    isUpdatable: Boolean,
    currentStep: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {
      isValid: false,
      isEditing: false,
      ltvExamResult: {
        groupExpectation1: '',
        groupExpectation2: ''
      }
    }
  },
  computed: {
    closeFillInfoTime() {
      if (this.systemTime.documentSystemTime && this.systemTime.documentSystemTime['close-fill-info'])
        return `${moment(this.systemTime.documentSystemTime['close-fill-info'], 'DD/MM/YYYY HH:mm:ss').format(
          'DD/MM/YYYY'
        )} lúc ${moment(this.systemTime.documentSystemTime['close-fill-info'], 'DD/MM/YYYY HH:mm:ss').format('HH:mm')}`
      return '21/06/2023 lúc 00:00'
    },
    isCloseFillInfo() {
      return this.systemTime.checkDocumentSystemTime && this.systemTime.checkDocumentSystemTime['close-fill-info']
    },
    isEditable() {
      return this.isEditing
    },
    isNotEditable() {
      return !this.isEditing
    }
  },
  created() {
    // if (this.documentStep > this.currentStep) this.isEditing = false
    if (this.document.ltvExamResult) this.ltvExamResult = this.document.ltvExamResult
  },
  methods: {
    reset() {
      this.$refs.expectationForm.reset()
    },
    completeStep() {
      const groupExpectation1 = this.$refs['expectation-form-1'].getData()
      const groupExpectation2 = this.$refs['expectation-form-2'].getData()
      if (groupExpectation1 && groupExpectation2) {
        this.$dialog.confirm({
          title: 'Chú ý',
          okText: 'Xác nhận',
          topContent: '',
          midContent: 'Nếu đã chắc chắn quý phụ huynh bấm vào nút xác nhận bên dưới để tiếp tục',
          botContent: '',
          cancelText: 'Kiểm tra lại',
          done: async () => {
            this.$loading.active = true
            this.$emit('completeStep', {
              ltvExamResult: {
                ...this.document.ltvExamResult,
                groupExpectation1,
                groupExpectation2
              }
            })
          }
        })
      }
    },
    saveDraft() {
      const groupExpectation1 = this.$refs['expectation-form-1'].getData()
      const groupExpectation2 = this.$refs['expectation-form-2'].getData()
      if (groupExpectation1 && groupExpectation2) {
        this.$emit('saveDraft', {
          ltvExamResult: {
            ...this.document.ltvExamResult,
            ...this.ltvExamResult,
            groupExpectation1,
            groupExpectation2
          },
          callback: () => (this.isEditing = false)
        })
      }
    },
    nextStep() {
      this.$loading.active = true
      this.$emit('nextStep')
    },
    onEdit() {
      this.$dialog.confirm({
        title: 'Chỉnh sửa',
        okText: 'Xác nhận',
        topContent: `Phụ huynh lưu ý: Các thông tin này có thể được chỉnh sửa nhưng sẽ bị khóa vào ngày <span class="error--text">${this.closeFillInfoTime}</span>`,
        midContent: 'Nếu đã chắc chắn quý phụ huynh bấm vào nút xác nhận bên dưới để tiếp tục',
        botContent: '',
        cancelText: 'Hủy',
        done: async () => {
          this.isEditing = true
        }
      })
    },
    openLinkToMoet() {
      window.open('https://data.moet.gov.vn/index.php/s/LETzPhj5sGGnDii#pdfviewer', '_blank', 'noopener noreferrer')
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.card-title {
  padding: 0 !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}
hr.dashed {
  width: 100%;
  border: 1px dashed #e6e4eb;
}
.section-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #3e3e3c;
}

@media only screen and (max-width: 420px) {
  .card-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 350px) {
  .card-title {
    font-size: 17px;
  }
}

@media only screen and (max-width: 500px) and (min-width: 400px) {
  .card-title {
    font-size: 21px;
  }
}
</style>
