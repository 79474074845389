var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._b({attrs:{"item-key":"id","headers":_vm.headers,"loading":_vm.loading,"items":_vm.notifications,"items-per-page":10,"disable-sort":"","footer-props":{'items-per-page-text': 'Số thông báo một trang'}},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(items){return [_vm._v(" Thông báo thứ "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" trên tổng "+_vm._s(items.itemsLength)+" thông báo ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.user && item.user.username) || '')+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getCreatedAt")(item))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'success')?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])]}},{key:"item.receiver",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getReceiver")(item))+" ")]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"d-flex flex-column align-center justify-center pa-6"},[_c('div',{staticClass:"mt-4 text-subtitle-1"},[_vm._v("Chưa có dữ liệu")])])]},proxy:true},{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.content)}})]}}],null,true)},'v-data-table',this.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }