<template>
  <div class="pa-6">
    <DocumentDetailDialog :state="dialog" :documentId="selectedDocumentId" @closeDialog="dialog = !dialog" />
    <div class="d-flex justify-space-between align-center mb-6">
      <div class="component-title">Quản lý hồ sơ</div>
      <div class="flex-center">
        <JsonExcel
          :data="updatedCVs"
          :fields="json_fields"
          :before-generate="toggleLoadingScreen(true)"
          :before-finish="toggleLoadingScreen(false)"
          worksheet="Hồ sơ"
          name="ho-so.xls"
        >
          <v-btn color="admin" dark outlined> <v-icon left>mdi-file-excel-outline</v-icon>Xuất Excel </v-btn>
        </JsonExcel>
      </div>
    </div>
    <v-card class="pa-6 elevation-1 mb-6">
      <DocumentFilter @onFilterChanged="onFilterChanged" />
    </v-card>

    <v-card class="elevation-1">
      <DocumentTable ref="documentTable" @onDocumentDetail="onDocumentDetail" />
    </v-card>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import {mapActions, mapGetters} from 'vuex'
import DocumentFilter from './DocumentFilter'
import DocumentTable from './DocumentTable'
import DocumentDetailDialog from './DocumentDetailDialog'
import JsonExcel from 'vue-json-excel'
import moment from 'moment'
import {get} from 'lodash'
// import _ from "lodash";

export default {
  components: {
    DocumentDetailDialog,
    DocumentFilter,
    DocumentTable,
    JsonExcel
  },
  props: {
    role: String
  },
  data() {
    return {
      loading: false,
      selectedDocumentId: '',
      searchType: '',
      updatedCVs: [],
      dialog: false,
      json_fields: {
        'Mã hồ sơ': 'code',
        'Trạng thái': {
          field: 'status',
          callback: value => {
            if (value === 'submitted') return 'Đã nộp'
            else if (value === 'created') return 'Vừa tạo'
            else if (value === 'disabled') return 'Đang tắt'
            else return 'Đang khai'
          }
        },
        Khối: {
          field: 'type',
          callback: value => {
            if (value === 'Khối 6') return '6'
            else return '10'
          }
        },
        'Tạo lúc': {
          field: 'createdAt',
          callback: value => {
            return moment(value).format('DD/MM/YYYY HH:mm:ss')
          }
        },
        'Cập nhật lúc': {
          field: 'updatedAt',
          callback: value => {
            return moment(value).format('DD/MM/YYYY HH:mm:ss')
          }
        },
        'Tài khoản kích hoạt': {
          field: 'username',
          callback: value => {
            return value
          }
        },
        'Cơ sở': {
          field: 'department',
          callback: value => {
            if (value === 'unset') return 'Chưa có thông tin'
            else return value
          }
        },
        'Họ và tên': 'name',
        'Ngày sinh': {
          field: 'dob',
          callback: value => {
            if (value) return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
            return ''
          }
        },
        'Giới tính': {
          field: 'gender',
          callback: value => {
            return value === 'female' ? 'Nữ' : 'Nam'
          }
        },
        'Mã học sinh': 'studentId',
        'Trường từng học': 'school',
        'Thành phố': 'city',
        'Thông tin người khai': {
          field: 'parent',
          callback: value => {
            return (
              `Họ và tên: ${value.parentName || ''}\n` +
              `Số điện thoại: ${value.parentPhone || ''}\n` +
              `Số chứng minh thư: ${value.parentResidentID || ''}\n` +
              `Nghề nghiệp: ${value.parentJob || ''}\n` +
              `Địa chỉ: ${value.parentAddress || ''}`
            )
          }
        },
        'Nguyện vọng 1': {
          field: 'expectation1',
          callback: value => {
            if (value.clazz) return value.clazz
            else if (value.school) return value.school
            else return ''
          }
        },
        'Nguyện vọng 2': {
          field: 'expectation2',
          callback: value => {
            if (value.clazz) return value.clazz
            else if (value.school) return value.school
            else return ''
          }
        },
        'Nguyện vọng 3': {
          field: 'expectation3',
          callback: value => {
            if (value.clazz) return value.clazz
            else if (value.school) return value.school
            else return ''
          }
        },
        T1: {
          field: 'studyRecord',
          callback: value => {
            return value.grade1Math
          }
        },
        V1: {
          field: 'studyRecord',
          callback: value => {
            return value.grade1Literature
          }
        },
        A1: {
          field: 'studyRecord',
          callback: value => {
            return value.grade1English
          }
        },
        T2: {
          field: 'studyRecord',
          callback: value => {
            return value.grade2Math
          }
        },
        V2: {
          field: 'studyRecord',
          callback: value => {
            return value.grade2Literature
          }
        },
        A2: {
          field: 'studyRecord',
          callback: value => {
            return value.grade2English
          }
        },
        T3: {
          field: 'studyRecord',
          callback: value => {
            return value.grade3Math
          }
        },
        V3: {
          field: 'studyRecord',
          callback: value => {
            return value.grade3Literature
          }
        },
        A3: {
          field: 'studyRecord',
          callback: value => {
            return value.grade3English
          }
        },
        T4: {
          field: 'studyRecord',
          callback: value => {
            return value.grade4Math
          }
        },
        V4: {
          field: 'studyRecord',
          callback: value => {
            return value.grade4Literature
          }
        },
        A4: {
          field: 'studyRecord',
          callback: value => {
            return value.grade4English
          }
        },
        T5: {
          field: 'studyRecord',
          callback: value => {
            return value.grade5Math
          }
        },
        V5: {
          field: 'studyRecord',
          callback: value => {
            return value.grade5Literature
          }
        },
        A5: {
          field: 'studyRecord',
          callback: value => {
            return value.grade5English
          }
        },
        'Tổng điểm tổng kết toán văn Khối 6': {
          field: 'studyRecord',
          callback: value => {
            return value.totalMathLiterature
          }
        },
        T6: {
          field: 'studyRecord',
          callback: value => {
            return value.grade6Math
          }
        },
        V6: {
          field: 'studyRecord',
          callback: value => {
            return value.grade6Literature
          }
        },
        A6: {
          field: 'studyRecord',
          callback: value => {
            return value.grade6English
          }
        },
        L6: {
          field: 'studyRecord',
          callback: value => {
            return value.grade6Chemistry
          }
        },
        HK6: {
          field: 'studyRecord',
          callback: value => {
            return value.grade6Morality
          }
        },
        T7: {
          field: 'studyRecord',
          callback: value => {
            return value.grade7Math
          }
        },
        V7: {
          field: 'studyRecord',
          callback: value => {
            return value.grade7Literature
          }
        },
        A7: {
          field: 'studyRecord',
          callback: value => {
            return value.grade7English
          }
        },
        L7: {
          field: 'studyRecord',
          callback: value => {
            return value.grade7Chemistry
          }
        },
        HK7: {
          field: 'studyRecord',
          callback: value => {
            return value.grade7Morality
          }
        },
        T8: {
          field: 'studyRecord',
          callback: value => {
            return value.grade8Math
          }
        },
        V8: {
          field: 'studyRecord',
          callback: value => {
            return value.grade8Literature
          }
        },
        A8: {
          field: 'studyRecord',
          callback: value => {
            return value.grade8English
          }
        },
        L8: {
          field: 'studyRecord',
          callback: value => {
            return value.grade8Chemistry
          }
        },
        H8: {
          field: 'studyRecord',
          callback: value => {
            return value.grade8Chemistry
          }
        },
        HK8: {
          field: 'studyRecord',
          callback: value => {
            return value.grade8Morality
          }
        },
        T9: {
          field: 'studyRecord',
          callback: value => {
            return value.grade9Math
          }
        },
        V9: {
          field: 'studyRecord',
          callback: value => {
            return value.grade9Literature
          }
        },
        A9: {
          field: 'studyRecord',
          callback: value => {
            return value.grade9English
          }
        },
        L9: {
          field: 'studyRecord',
          callback: value => {
            return value.grade9Physics
          }
        },
        H9: {
          field: 'studyRecord',
          callback: value => {
            return value.grade9Chemistry
          }
        },
        HK9: {
          field: 'studyRecord',
          callback: value => {
            return value.grade9Morality
          }
        },
        'Thành tích nổi bật': 'studyRecord.achievements',
        'Diện tuyển thẳng (khối 10)': 'studyRecord.recuitingStraightType',
        'Nguyện vọng Ban (khối 10)': {
          callback: value => {
            if (value.type === 'Khối 10') {
              let NV1 = this.getGroupExpectation(get(value, 'ltvExamResult.groupExpectation1'))
              let NV2 = this.getGroupExpectation(get(value, 'ltvExamResult.groupExpectation2'))
              if (NV1 !== '---') NV1 = '\n' + NV1
              if (NV2 !== '---') NV2 = '\n' + NV2
              return `Nguyện vọng 1: ${NV1}\nNguyện vọng 2: ${NV2}`
            }
            return ''
          }
        },
        'Số báo danh dự thi': 'ltvExamResult.studentExamID',
        'Điểm thi Toán': {
          field: 'ltvExamResult',
          callback: value => {
            return value.examMath || value.examMathMark
          }
        },
        'Điểm thi Văn': 'ltvExamResult.examLiterature',
        'Điểm thi Anh': 'ltvExamResult.examEnglish',
        'Điểm ưu tiên (khối 6)': 'ltvExamResult.priorityMark',
        'Tổng điểm (khối 6)': {
          callback: value => {
            if (value.type === 'Khối 6') {
              const studentProfile = Number(get(value, 'studyRecord.totalMathLiterature', 0))
              const priority = Number(get(value, 'ltvExamResult.priorityMark', 0))
              let examMath = Number(get(value, 'ltvExamResult.examMathMark', 0))
              let examLiterature = Number(get(value, 'ltvExamResult.examLiterature', 0))
              let examEnglish = Number(get(value, 'ltvExamResult.examEnglish', 0))
              if (isNaN(examMath)) examMath = 0
              if (isNaN(examLiterature)) examLiterature = 0
              if (isNaN(examEnglish)) examEnglish = 0
              return ((examMath + examLiterature + examEnglish) * 2 + studentProfile + priority).toFixed(2)
            }
            return '---'
          }
        },
        'Tổng điểm (khối 10)': 'ltvExamResult.entranceMark',
        'Kết quả trúng tuyển': 'ltvExamResult.passExamText'
      }
    }
  },
  computed: {
    ...mapGetters('cv', ['CVs', 'CV']),
    ...mapGetters('auth', ['user', 'isAuthenticated']),
    addButtonText() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return 'Thêm'
        default:
          return 'Thêm lớp học'
      }
    },
    noAvatarCVs() {
      return this.CVs.filter(cv => !cv.avatar || cv.avatar == '' || cv.avatar.length == 0)
    }
  },
  watch: {
    CVs(newValue) {
      this.updateCVs(newValue)
    }
  },
  methods: {
    ...mapActions('cv', ['fetchCVs', 'startHourlySMSTask', 'setCVs']),
    getGroupExpectationChildren(group, prefix = '') {
      if (Array.isArray(group) && group.length) {
        return prefix + group.join(', ')
      }
      return ''
    },
    getGroupExpectation(expectation) {
      let result = []
      const childKHXH = this.getGroupExpectationChildren(get(expectation, 'groupKHXH', []), '- KHXH: ')
      const childKHTN = this.getGroupExpectationChildren(get(expectation, 'groupKHTN', []), '- KHTN: ')
      const childCNNT = this.getGroupExpectationChildren(get(expectation, 'groupCNNT', []), '- CNNT: ')
      if (childKHXH.length) result.push(childKHXH)
      if (childKHTN.length) result.push(childKHTN)
      if (childCNNT.length) result.push(childCNNT)
      if (result.length) return result.join(`\n`)
      return '---'
    },
    async onFilterChanged(data) {
      this.$loading.active = true
      this.searchType = data.type
      await this.fetchCVs({
        ...data,
        _sort: 'updatedAt:DESC'
      })
      this.$loading.active = false
    },
    onDocumentDetail(data) {
      this.dialog = true
      this.selectedDocumentId = data
    },
    toggleLoadingScreen(data) {
      this.loading = data
      this.$loading.active = data
    },
    updateCVs(data) {
      this.updatedCVs = data.map(cv => {
        return {
          ...cv,
          username: cv.parent ? cv.parent.username : '',
          parent: {
            parentName: cv.parentName,
            parentPhone: cv.parentPhone,
            parentAddress: cv.parentAddress,
            parentJob: cv.parentJob,
            parentResidentID: cv.parentResidentID
          },
          otherParent: {
            otherParentName: cv.otherParentName,
            otherParentPhone: cv.otherParentPhone,
            otherParentAddress: cv.otherParentAddress,
            otherParentJob: cv.otherParentJob,
            otherParentResidentID: cv.otherParentResidentID
          }
        }
      })
    },
    // setJsonFieldsBeforeExport() {
    //   this.toggleLoadingScreen(true)
    //   const json = {}
    //   if (this.searchType === 'Khối 6') {
    //     json = {

    //     }
    //   } else if (this.searchType === 'Khối 10') {

    //   } else {

    //   }
    //   this.json_fields = {...json}
    // }
  },
  created() {
    this.updateCVs(this.CVs)
  }
}
/* eslint-enable no-unused-vars */
</script>

<style scoped>
.chip-width {
  min-width: 82px;
  justify-content: center;
}
.component-title {
  color: #0d47a1;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}
</style>
