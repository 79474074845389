<template>
  <v-row class="footer-row d-flex align-center justify-end" :style="getFooterStyle" no-gutters>
    <v-spacer></v-spacer>
    <v-col cols="2" xs="2" sm="2" md="2" lg="1" v-if="$vuetify.breakpoint.smAndDown"
      ><span class="font-weight-medium body-text ml-2">Hotline:</span>
      <div style="color: #3e3e3c" v-if="$vuetify.breakpoint.smAndDown" />
    </v-col>
    <v-col cols="10" xs="5" sm="6" md="9" :class="{'d-flex justify-end mr-4': $vuetify.breakpoint.mdAndUp}">
      <div class="mr-4 font-weight-medium body-text" v-if="$vuetify.breakpoint.mdAndUp" style="line-height: 26px;">
        Hotline:
      </div>
      <div class="d-flex flex-column">
        <div class="font-weight-light">
          <span class="body-text"
            >Cơ sở A - Cầu Giấy: <a href="tel:0374136655" class="text-decoration-none body-text">037.413.6655</a></span
          >
        </div>
        <div class="font-weight-light" :class="{'mr-4': $vuetify.breakpoint.mdAndUp}">
          <span class="body-text"
            >Cơ sở 1 - Tân Triều:
            <a href="tel:02435682603" class="text-decoration-none body-text">024.356.82603</a></span
          >
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  computed: {
    getFooterStyle() {
      if (this.$vuetify.breakpoint.mdAndUp) return 'height: 52px; left: 0; width: 100%; z-index: 11;'
      else return 'height: 72px; width: 100%; z-index:11'
    }
  }
}
</script>
<style scoped>
.footer-row {
  position: absolute;
  left: 0px;
  bottom: 0px;
  background-color: #3e3e3c;
  color: white;
}
.body-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}
</style>
