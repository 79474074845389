<template>
  <div class="pa-6">
    <div class="d-flex justify-space-between align-center">
      <div class="component-title">Báo cáo</div>
      <div class="component-title">{{ getCurrentDepartment }}</div>
    </div>

    <div class="section-title py-6">Mã hồ sơ</div>
    <v-row class="d-flex">
      <v-col cols="6">
        <v-card>
          <v-card-title class="card-title pa-6">Khối 6</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="d-flex justify-space-between pa-6">
            <div>
              <div class="text-number pb-3 admin--text">
                {{ grade6ActiveCode.totalActiveCodes }}
              </div>
              <div>Tổng số mã đã tạo</div>
            </div>
            <div>
              <div class="text-number pb-3 success--text">
                {{ grade6ActiveCode.activatedActiveCodes }}
              </div>
              <div>Số mã đã kích hoạt</div>
            </div>
            <div>
              <div class="text-number pb-3 warning--text">
                {{ grade6ActiveCode.inactiveActiveCodes }}
              </div>
              <div>Số mã chưa kích hoạt</div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title class="card-title pa-6">Khối 10</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="d-flex justify-space-between pa-6">
            <div>
              <div class="text-number pb-3 admin--text">
                {{ grade10ActiveCode.totalActiveCodes }}
              </div>
              <div>Tổng số mã đã tạo</div>
            </div>
            <div>
              <div class="text-number pb-3 success--text">
                {{ grade10ActiveCode.activatedActiveCodes }}
              </div>
              <div>Số mã đã kích hoạt</div>
            </div>
            <div>
              <div class="text-number pb-3 warning--text">
                {{ grade10ActiveCode.inactiveActiveCodes }}
              </div>
              <div>Số mã chưa kích hoạt</div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div class="section-title py-6">Hồ sơ</div>
    <v-row no-gutters>
      <v-card style="width: 100%">
        <v-card-title class="card-title pa-6">Khối 6</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-6">
          <v-row class="d-flex align-center" no-gutters>
            <v-col cols="12" sm="12" xs="12" md="3">
              <div class="section-title px-2">Phát sinh hồ sơ</div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="12" xs="12" md="3">
              <RangeDatePicker @change="changeDateRange($event, 'Khối 6')" />
            </v-col>
          </v-row>
          <div>
            <LineChart
              style="height: 275px; width: 100%; position: 'relative'"
              :chartdata="grade6LineChart"
              :options="lineChartOptions"
            />
          </div>
          <v-row class="d-flex mt-6" no-gutters>
            <v-col cols="7" class="d-flex flex-column align-start" style="border-right: 1px solid #e0e0e0">
              <div class="section-title py-8 px-2">Tình trạng hồ sơ</div>
              <PieChart
                :chartdata="grade6PieChart"
                :options="pieChartOptions"
                class="align-self-center"
                style="width: 315px"
              />
              <div class="text-number align-self-center black--text">
                {{ grade6Document.total }}
              </div>
              <div class="timeline-title align-self-center py-1">
                Hồ sơ đã tạo
              </div>
            </v-col>
            <v-col cols="5" class="d-flex flex-column align-start">
              <div class="section-title py-8 px-6">Hồ sơ đang khai báo</div>
              <v-timeline dense clipped>
                <v-timeline-item fill-dot class="white--text mb-12 pb-0" color="white" small>
                  <template v-slot:icon>
                    <v-icon color="admin"> mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <div>
                    <div class="timeline-title">Chọn sơ sở</div>
                    <div class="timeline-subtitle py-2">Số hồ sơ hoàn tất</div>
                    <div class="text-number admin--text text-start">
                      {{
                        user.department === 'both'
                          ? grade6Document.chooseFacility
                          : user.department === 'Cơ sở A'
                          ? grade6Document.chooseFacilityA
                          : grade6Document.chooseFacility1
                      }}
                    </div>
                    <div class="timeline-subtitle py-2" v-if="user.department === 'both'">
                      Cơ sở A:
                      <span class="admin--text">{{ grade6Document.chooseFacilityA }}</span>
                      / Cơ sở 1:
                      <span class="admin--text">{{ grade6Document.chooseFacility1 }}</span>
                    </div>
                  </div>
                </v-timeline-item>
                <v-timeline-item fill-dot class="white--text mb-12 pb-0" color="white" small>
                  <template v-slot:icon>
                    <v-icon color="admin"> mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <div>
                    <div class="timeline-title">
                      Thông tin phụ huynh
                    </div>
                    <div class="timeline-subtitle py-2">Số hồ sơ hoàn tất</div>
                    <div class="text-number admin--text text-start">
                      {{
                        user.department === 'both'
                          ? grade6Document.infoParent
                          : user.department === 'Cơ sở A'
                          ? grade6Document.infoParentA
                          : grade6Document.infoParent1
                      }}
                    </div>
                    <div class="timeline-subtitle py-2" v-if="user.department === 'both'">
                      Cơ sở A:
                      <span class="admin--text">{{ grade6Document.infoParentA }}</span>
                      / Cơ sở 1:
                      <span class="admin--text">{{ grade6Document.infoParent1 }}</span>
                    </div>
                  </div>
                </v-timeline-item>
                <v-timeline-item fill-dot class="white--text mb-12 pb-0" color="white" small>
                  <template v-slot:icon>
                    <v-icon color="admin"> mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <div>
                    <div class="timeline-title">
                      Thông tin học sinh
                    </div>
                    <div class="timeline-subtitle py-2">Số hồ sơ hoàn tất</div>
                    <div class="text-number admin--text text-start">
                      {{
                        user.department === 'both'
                          ? grade6Document.infoStudent
                          : user.department === 'Cơ sở A'
                          ? grade6Document.infoStudentA
                          : grade6Document.infoStudent1
                      }}
                    </div>
                    <div class="timeline-subtitle py-2" v-if="user.department === 'both'">
                      Cơ sở A:
                      <span class="admin--text">{{ grade6Document.infoStudentA }}</span>
                      / Cơ sở 1:
                      <span class="admin--text">{{ grade6Document.infoStudent1 }}</span>
                    </div>
                  </div>
                </v-timeline-item>
                <v-timeline-item fill-dot class="white--text mb-12 pb-0" color="white" small>
                  <template v-slot:icon>
                    <v-icon color="admin"> mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <div>
                    <div class="timeline-title">Kết quả học tập</div>
                    <div class="timeline-subtitle py-2">Số hồ sơ hoàn tất</div>
                    <div class="text-number admin--text text-start">
                      {{
                        user.department === 'both'
                          ? grade6Document.studyResult
                          : user.department === 'Cơ sở A'
                          ? grade6Document.studyResultA
                          : grade6Document.studyResult1
                      }}
                    </div>
                    <div class="timeline-subtitle py-2" v-if="user.department === 'both'">
                      Cơ sở A:
                      <span class="admin--text">{{ grade6Document.studyResultA }}</span>
                      / Cơ sở 1:
                      <span class="admin--text">{{ grade6Document.studyResult1 }}</span>
                    </div>
                  </div>
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row class="mt-6" no-gutters>
      <v-card style="width: 100%">
        <v-card-title class="card-title pa-6">Khối 10</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-6">
          <v-row class="d-flex align-center" no-gutters>
            <v-col cols="12" sm="12" xs="12" md="3">
              <div class="section-title px-2">Phát sinh hồ sơ</div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="12" xs="12" md="3">
              <RangeDatePicker @change="changeDateRange($event, 'Khối 10')" />
            </v-col>
          </v-row>
          <div>
            <LineChart
              style="height: 275px; width: 100%; position: 'relative'"
              :chartdata="grade10LineChart"
              :options="lineChartOptions"
            />
          </div>
          <v-row class="d-flex mt-6" no-gutters>
            <v-col cols="7" class="d-flex flex-column align-start" style="border-right: 1px solid #e0e0e0">
              <div class="section-title py-8 px-2">Tình trạng hồ sơ</div>
              <PieChart
                :chartdata="grade10PieChart"
                :options="pieChartOptions"
                class="align-self-center"
                style="width: 315px"
              />
              <div class="text-number align-self-center black--text">
                {{ grade10Document.total }}
              </div>
              <div class="timeline-title align-self-center py-1">
                Hồ sơ đã tạo
              </div>
            </v-col>
            <v-col cols="5" class="d-flex flex-column align-start">
              <div class="section-title py-8 px-6">Hồ sơ đang khai báo</div>
              <v-timeline dense clipped>
                <v-timeline-item fill-dot class="white--text mb-12 pb-0" color="white" small>
                  <template v-slot:icon>
                    <v-icon color="admin"> mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <div>
                    <div class="timeline-title">Chọn sơ sở</div>
                    <div class="timeline-subtitle py-2">Số hồ sơ hoàn tất</div>
                    <div class="text-number admin--text text-start">
                      {{
                        user.department === 'both'
                          ? grade10Document.chooseFacility
                          : user.department === 'Cơ sở A'
                          ? grade10Document.chooseFacilityA
                          : grade10Document.chooseFacility1
                      }}
                    </div>
                    <div class="timeline-subtitle py-2" v-if="user.department === 'both'">
                      Cơ sở A:
                      <span class="admin--text">{{ grade10Document.chooseFacilityA }}</span>
                      / Cơ sở 1:
                      <span class="admin--text">{{ grade10Document.chooseFacility1 }}</span>
                    </div>
                  </div>
                </v-timeline-item>
                <v-timeline-item fill-dot class="white--text mb-12 pb-0" color="white" small>
                  <template v-slot:icon>
                    <v-icon color="admin"> mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <div>
                    <div class="timeline-title">
                      Thông tin phụ huynh
                    </div>
                    <div class="timeline-subtitle py-2">Số hồ sơ hoàn tất</div>
                    <div class="text-number admin--text text-start">
                      {{
                        user.department === 'both'
                          ? grade10Document.infoParent
                          : user.department === 'Cơ sở A'
                          ? grade10Document.infoParentA
                          : grade10Document.infoParent1
                      }}
                    </div>
                    <div class="timeline-subtitle py-2" v-if="user.department === 'both'">
                      Cơ sở A:
                      <span class="admin--text">{{ grade10Document.infoParentA }}</span>
                      / Cơ sở 1:
                      <span class="admin--text">{{ grade10Document.infoParent1 }}</span>
                    </div>
                  </div>
                </v-timeline-item>
                <v-timeline-item fill-dot class="white--text mb-12 pb-0" color="white" small>
                  <template v-slot:icon>
                    <v-icon color="admin"> mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <div>
                    <div class="timeline-title">
                      Thông tin học sinh
                    </div>
                    <div class="timeline-subtitle py-2">Số hồ sơ hoàn tất</div>
                    <div class="text-number admin--text text-start">
                      {{
                        user.department === 'both'
                          ? grade10Document.infoStudent
                          : user.department === 'Cơ sở A'
                          ? grade10Document.infoStudentA
                          : grade10Document.infoStudent1
                      }}
                    </div>
                    <div class="timeline-subtitle py-2" v-if="user.department === 'both'">
                      Cơ sở A:
                      <span class="admin--text">{{ grade10Document.infoStudentA }}</span>
                      / Cơ sở 1:
                      <span class="admin--text">{{ grade10Document.infoStudent1 }}</span>
                    </div>
                  </div>
                </v-timeline-item>
                <v-timeline-item fill-dot class="white--text mb-12 pb-0" color="white" small>
                  <template v-slot:icon>
                    <v-icon color="admin"> mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <div>
                    <div class="timeline-title">Kết quả học tập</div>
                    <div class="timeline-subtitle py-2">Số hồ sơ hoàn tất</div>
                    <div class="text-number admin--text text-start">
                      {{
                        user.department === 'both'
                          ? grade10Document.studyResult
                          : user.department === 'Cơ sở A'
                          ? grade10Document.studyResultA
                          : grade10Document.studyResult1
                      }}
                    </div>
                    <div class="timeline-subtitle py-2" v-if="user.department === 'both'">
                      Cơ sở A:
                      <span class="admin--text">{{ grade10Document.studyResultA }}</span>
                      / Cơ sở 1:
                      <span class="admin--text">{{ grade10Document.studyResult1 }}</span>
                    </div>
                  </div>
                </v-timeline-item>
                <v-timeline-item fill-dot class="white--text mb-12 pb-0" color="white" small>
                  <template v-slot:icon>
                    <v-icon color="admin"> mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <div>
                    <div class="timeline-title">Đăng ký nguyện vọng</div>
                    <div class="timeline-subtitle py-2">Số hồ sơ hoàn tất</div>
                    <div class="text-number admin--text text-start">
                      {{
                        user.department === 'both'
                          ? grade10Document.expectation
                          : user.department === 'Cơ sở A'
                          ? grade10Document.expectationA
                          : grade10Document.expectation1
                      }}
                    </div>
                    <div class="timeline-subtitle py-2" v-if="user.department === 'both'">
                      Cơ sở A:
                      <span class="admin--text">{{ grade10Document.expectationA }}</span>
                      / Cơ sở 1:
                      <span class="admin--text">{{ grade10Document.expectationA }}</span>
                    </div>
                  </div>
                </v-timeline-item>
                <v-timeline-item fill-dot class="white--text mb-12 pb-0" color="white" small>
                  <template v-slot:icon>
                    <v-icon color="admin"> mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <div>
                    <div class="timeline-title">Tổ hợp môn học tự chọn</div>
                    <div class="timeline-subtitle py-2">Số hồ sơ hoàn tất</div>
                    <div class="text-number admin--text text-start">
                      {{
                        user.department === 'both'
                          ? grade10Document.groupExpectation
                          : user.department === 'Cơ sở A'
                          ? grade10Document.groupExpectationA
                          : grade10Document.groupExpectation1
                      }}
                    </div>
                    <div class="timeline-subtitle py-2" v-if="user.department === 'both'">
                      Cơ sở A:
                      <span class="admin--text">{{ grade10Document.groupExpectationA }}</span>
                      / Cơ sở 1:
                      <span class="admin--text">{{ grade10Document.groupExpectation1 }}</span>
                    </div>
                  </div>
                </v-timeline-item>
                <v-timeline-item fill-dot class="white--text mb-12 pb-0" color="white" small>
                  <template v-slot:icon>
                    <v-icon color="admin"> mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <div>
                    <div class="timeline-title">Kết quả Kỳ thi tuyển sinh</div>
                    <div class="timeline-subtitle py-2">Số hồ sơ hoàn tất</div>
                    <div class="text-number admin--text text-start">
                      {{
                        user.department === 'both'
                          ? grade10Document.examResult
                          : user.department === 'Cơ sở A'
                          ? grade10Document.examResultA
                          : grade10Document.examResult1
                      }}
                    </div>
                    <div class="timeline-subtitle py-2" v-if="user.department === 'both'">
                      Cơ sở A:
                      <span class="admin--text">{{ grade10Document.examResultA }}</span>
                      / Cơ sở 1:
                      <span class="admin--text">{{ grade10Document.examResult1 }}</span>
                    </div>
                  </div>
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import moment from 'moment'
import LineChart from '@/components/chart/LineChart.vue'
import PieChart from '@/components/chart/PieChart.vue'
import RangeDatePicker from '@/components/basic/RangeDatePicker.vue'
export default {
  components: {LineChart, PieChart, RangeDatePicker},
  computed: {
    ...mapGetters('cv', ['CVs', 'CV', 'count']),
    ...mapGetters('activeCode', ['activeCode', 'activeCodes', 'count']),
    ...mapGetters('auth', ['user']),
    getCurrentDepartment() {
      if (this.user && this.user.department != 'both') return this.user.department
      else return 'Cơ sở 1 và Cơ sở A'
    }
  },
  methods: {
    ...mapActions('activeCode', ['countActiveCodes', 'fetchActiveCodes', 'fetchActiveCode']),
    ...mapActions('cv', ['fetchCVs', 'fetchCV', 'countCVs', 'fetchStats']),
    async getActiveCodeStatistics(grade) {
      const params = {grade, department_in: this.department}
      const [totalActiveCodes, inactiveActiveCodes, activatedActiveCodes] = await Promise.all([
        this.countActiveCodes(params),
        this.countActiveCodes({...params, status: 'inactive'}),
        this.countActiveCodes({...params, status: 'active'})
      ])
      return {totalActiveCodes, inactiveActiveCodes, activatedActiveCodes}
    },
    async changeDateRange(data, grade) {
      if (grade === 'Khối 6') {
        this.$loading.active = true
        this.grade6StartDate = data[0]
        this.grade6EndDate = data[1]
        this.grade6LineChart = await this.getLineChartData('Khối 6', this.grade6StartDate, this.grade6EndDate)
        this.$loading.active = false
      } else {
        this.$loading.active = true
        this.grade10StartDate = data[0]
        this.grade10EndDate = data[1]
        this.grade10LineChart = await this.getLineChartData('Khối 10', this.grade10StartDate, this.grade10EndDate)
        this.$loading.active = false
      }
    },
    getPieChartData(grade) {
      return {
        labels: ['Hồ sơ chưa khai báo', 'Hồ sơ đang khai báo', 'Hồ sơ đã nộp'],
        datasets: [
          {
            backgroundColor: ['#0D47A1', '#BBDEFB', '#85A7C2'],
            data:
              grade === 'Khối 6'
                ? [
                    this.grade6Document.totalCreated,
                    this.grade6Document.totalFilling,
                    this.grade6Document.totalSubmitted
                  ]
                : [
                    this.grade10Document.totalCreated,
                    this.grade10Document.totalFilling,
                    this.grade10Document.totalSubmitted
                  ]
          }
        ]
      }
    },
    async getLineChartData(grade, startDate, endDate) {
      let daysArray = Array.from(Array(moment(endDate).diff(moment(startDate), 'days') + 1).keys())
      const newDocumentEachDay = await this.getEachDayNewDocumentNum(daysArray, grade, startDate)
      daysArray = daysArray.map(index => {
        return moment(startDate)
          .add(index, 'days')
          .format('DD/MM/YYYY')
      })
      return {
        labels: daysArray,
        datasets: [
          {
            label: 'Số hồ sơ tạo mới',
            backgroundColor: 'rgba(13,71,161,0.75)',
            pointBackgroundColor: '#0D47A1',
            data: newDocumentEachDay
          }
        ]
      }
    },
    /* eslint-disable no-unused-vars */
    async getEachDayNewDocumentNum(days, grade, startDate) {
      const promises = days.map(async (n, index) => {
        const query = {
          type: grade,
          createdAt_gt: moment(startDate)
            .add(index, 'days')
            .toISOString(),
          createdAt_lt: moment(startDate)
            .add(index + 1, 'days')
            .toISOString(),
          department_in: this.department
        }
        return await this.countCVs(query)
      })
      return await Promise.all(promises)
    },
    async fetchDocumentStats() {
      const [grade6Document, grade10Document] = await Promise.all([
        this.fetchStats('Khối 6'),
        this.fetchStats('Khối 10')
      ])
      this.grade6Document = grade6Document
      this.grade10Document = grade10Document
    }
  },
  async created() {
    this.$loading.active = true
    if (this.user.department === 'both') {
      this.department = ['Cơ sở 1', 'Cơ sở A', 'unset']
    } else this.department = [this.user.department, 'unset']

    // document stats
    await this.fetchDocumentStats()
    //Grade 6
    this.grade6ActiveCode = await this.getActiveCodeStatistics('Khối 6')
    this.grade6LineChart = await this.getLineChartData('Khối 6', this.grade6StartDate, this.grade6EndDate)
    this.grade6PieChart = this.getPieChartData('Khối 6')

    // // //Grade 10
    this.grade10ActiveCode = await this.getActiveCodeStatistics('Khối 10')
    this.grade10LineChart = await this.getLineChartData('Khối 10', this.grade10StartDate, this.grade10EndDate)
    this.grade10PieChart = this.getPieChartData('Khối 10')
    this.$loading.active = false
  },
  data() {
    return {
      department: [],
      pieChartOptions: {responsive: true, maintainAspectRatio: false},
      lineChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                stepSize: 10,
                reverse: false,
                beginAtZero: true
              }
            }
          ]
        }
      },
      //Grade 6
      grade6ActiveCode: {
        totalActiveCodes: 0,
        inactiveActiveCodes: 0,
        activatedActiveCodes: 0
      },
      grade6LineChart: {},
      grade6PieChart: {},
      grade6DateRange: [],
      grade6StartDate: moment()
        .utc()
        .clone()
        .startOf('week')
        .toISOString(),
      grade6EndDate: moment()
        .utc()
        .clone()
        .endOf('week')
        .toISOString(),
      grade6Document: {
        total: 0,
        totalCreated: 0,
        totalSubmitted: 0,
        totalFilling: 0,
        chooseFacility: 0,
        chooseFacility1: 0,
        chooseFacilityA: 0,
        infoForm: 0,
        infoForm1: 0,
        infoFormA: 0,
        studyForm: 0,
        studyForm1: 0,
        studyFormA: 0
      },
      //Grade 10
      grade10ActiveCode: {
        totalActiveCodes: 0,
        inactiveActiveCodes: 0,
        activatedActiveCodes: 0
      },
      grade10LineChart: {},
      grade10PieChart: {},
      grade10DateRange: [],
      grade10StartDate: moment()
        .utc()
        .clone()
        .startOf('week')
        .toISOString(),
      grade10EndDate: moment()
        .utc()
        .clone()
        .endOf('week')
        .toISOString(),
      grade10Document: {
        total: 0,
        totalCreated: 0,
        totalSubmitted: 0,
        totalFilling: 0,
        chooseFacility: 0,
        chooseFacility1: 0,
        chooseFacilityA: 0,
        infoForm: 0,
        infoForm1: 0,
        infoFormA: 0,
        expectation: 0,
        expectation1: 0,
        expectationA: 0,
        studyForm: 0,
        studyForm1: 0,
        studyFormA: 0,
        examResult: 0,
        examResult1: 0,
        examResultA: 0
      }
    }
  }
}
</script>

<style scoped>
.component-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #0d47a1;
}
.text-number {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
}
.section-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #212121;
}
.card-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #757575;
}
.timeline-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #757575;
}
.timeline-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #757575;
}
</style>
