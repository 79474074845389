<template>
  <div>
    <div class="info-label pb-3">Nguyện vọng {{ index }}</div>
    <v-row class="my-0">
      <v-col class="py-0" cols="12" v-if="isEditable">
        <v-form ref="expectationForm">
          <v-radio-group v-model="groupExpectation">
            <v-radio v-for="item in listExpectation" :key="item" :label="item" :value="item" />
          </v-radio-group>
        </v-form>
      </v-col>
      <v-col class="py-0" cols="12" v-else>
        <div class="info-label my-2">
          {{ item || 'Chưa có thông tin' }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    isEditable: {
      type: Boolean,
      default: false
    },
    item: {
      type: String,
      default: ''
    },
    isRequired: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: 1
    },
    department: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      DATA_CSA: ['Tổ hợp 1.A', 'Tổ hợp 2', 'Tổ hợp 3', 'Tổ hợp 4'],
      DATA_CS1: ['Tổ hợp 1.1', 'Tổ hợp 2', 'Tổ hợp 3'],
      listExpectation: [],
      groupExpectation: ''
    }
  },
  computed: {
    formRules() {
      let rules = [this.$rules.arrayLengthLowerThan(3, 'Số môn được chọn không quá')]
      if (this.isRequired) rules.shift(this.$rules.required)
      return rules
    }
  },
  methods: {
    handleItem(item) {
      this.groupExpectation = item
    },
    alertError({group = '', isMax = false}) {
      if (!isMax) this.$alert.error(`Tối thiểu số lượng môn học của ${group} đối với Nguyện vọng ${this.index} là 1.`)
      else this.$alert.error(`Tổng số môn học tối đa cho Nguyện vọng ${this.index} là 5.`)
    },
    validate() {
      const isFormValid = this.$refs['expectationForm'].validate()
      return isFormValid
    },
    getDataWithValidation(data) {
      const isValid = data.length > 0
      if (isValid) return data
      return false
    },
    getData(skipCheckValid = false) {
      if (skipCheckValid) return this.groupExpectation
      if (this.isRequired) return this.getDataWithValidation(this.groupExpectation)
      return this.getDataWhenOptional(this.groupExpectation)
    },
    getDataWhenOptional(data = {}) {
      let hasData = false
      Object.values(data).forEach(group => {
        if (group.length) hasData = true
      })
      if (hasData) return this.getDataWithValidation(data)
      return data
    },
    changeDataInGroup(val, key = 'KHXH') {
      const idx = this[`group${key}`].indexOf(val)
      if (idx < 0) this[`group${key}`].push(val)
      else this[`group${key}`].splice(idx, 1)
    }
  },
  watch: {
    item: {
      handler(item) {
        if (item) this.handleItem(item)
      },
      immediate: true
    },
    department: {
      handler(val) {
        if (val.length > 0 || val != 'unset') {
          this.listExpectation = val == 'Cơ sở A' ? this.DATA_CSA : this.DATA_CS1
        }
      },
      immediate: true
    }
  }
}
</script>
<style scoped>
.field-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #797979;
  margin-bottom: 4px;
}
.info-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3e3e3c;
}
</style>
