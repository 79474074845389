var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"980px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"title--text"},[_vm._v("Cơ sở vật chất")]),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){return _vm.cancel()}}},[_vm._v("mdi-close")])],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-0",class:{
        'd-flex': _vm.$vuetify.breakpoint.mdAndUp,
        'd-flex flex-column pb-8': _vm.$vuetify.breakpoint.smAndDown,
      }},[_c('v-card',{staticClass:"d-flex flex-column align-center elevation-0",class:{
          'mb-4': _vm.$vuetify.breakpoint.smAndDown,
          'desktop-btn py-8': _vm.$vuetify.breakpoint.mdAndUp,
        },on:{"click":function($event){return _vm.toggleFacilityADialog(true)}}},[_c('v-img',{staticClass:"d-flex align-end",class:{
            'pl-8': _vm.$vuetify.breakpoint.mdAndUp,
            'text-center': _vm.$vuetify.breakpoint.smAndDown,
          },attrs:{"src":require("@/assets/facility/facility1.svg"),"width":_vm.getImgWidth}},[_c('div',{class:{
              'facility-subtitle': _vm.$vuetify.breakpoint.smAndUp,
              'text-h6': _vm.$vuetify.breakpoint.xsOnly,
            }},[_vm._v(" Cơ sở A ")]),_c('div',{class:{
              'facility-title': _vm.$vuetify.breakpoint.smAndUp,
              'text-h5 font-weight-bold': _vm.$vuetify.breakpoint.xsOnly,
            }},[_vm._v(" Nam Trung Yên ")])]),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"btn-overlay"}):_vm._e()],1),_c('v-card',{staticClass:"d-flex flex-column align-center elevation-0",class:{ 'desktop-btn py-8': _vm.$vuetify.breakpoint.mdAndUp },on:{"click":function($event){return _vm.toggleFacility1Dialog(true)}}},[_c('v-img',{staticClass:"d-flex align-end",class:{
            'text-end pr-8': _vm.$vuetify.breakpoint.mdAndUp,
            'text-center': _vm.$vuetify.breakpoint.smAndDown,
          },attrs:{"src":require("@/assets/facility/facilityA.svg"),"width":_vm.getImgWidth}},[_c('div',{class:{
              'facility-subtitle': _vm.$vuetify.breakpoint.smAndUp,
              'text-h6': _vm.$vuetify.breakpoint.xsOnly,
            }},[_vm._v(" Cơ sở 1 ")]),_c('div',{class:{
              'facility-title': _vm.$vuetify.breakpoint.smAndUp,
              'text-h5 font-weight-bold': _vm.$vuetify.breakpoint.xsOnly,
            }},[_vm._v(" Tân Triều ")])]),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"btn-overlay"}):_vm._e()],1)],1)],1),_c('FacilityTour',{attrs:{"title":"Cơ sở A Nam Trung Yên","iframeSrc":(_vm.$baseUrl + "cs-2/index.html"),"state":_vm.facilityADialog},on:{"closeDialog":_vm.toggleFacilityADialog}}),_c('FacilityTour',{attrs:{"title":"Cơ sở 1 Tân Triều","iframeSrc":(_vm.$baseUrl + "ltv-360/index.html"),"state":_vm.facility1Dialog},on:{"closeDialog":_vm.toggleFacility1Dialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }