var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"740px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"title--text"},[_vm._v("Thông tin tuyển sinh")]),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){return _vm.cancel()}}},[_vm._v("mdi-close")])],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-0",class:{
        'd-flex': _vm.$vuetify.breakpoint.mdAndUp,
        'd-flex flex-column py-8': _vm.$vuetify.breakpoint.smOnly,
        'd-flex flex-column py-6': _vm.$vuetify.breakpoint.xsOnly,
      }},[_c('v-card',{staticClass:"d-flex flex-column align-center elevation-0 pa-4",class:{
          'desktop-btn py-12': _vm.$vuetify.breakpoint.mdAndUp,
          'mb-4': _vm.$vuetify.breakpoint.smOnly,
          'mb-3': _vm.$vuetify.breakpoint.xsOnly,
        },on:{"click":function($event){_vm.grade6Dialog = true}}},[_c('v-img',{attrs:{"src":require("@/assets/enroll/khoi-6.svg"),"width":"160px"}}),_c('div',{staticClass:"btn-title title--text mt-6"},[_vm._v(" Thông tin tuyển sinh Lớp 6 ")])],1),_c('v-card',{staticClass:"d-flex flex-column align-center elevation-0 pa-4",class:{ 'desktop-btn py-12': _vm.$vuetify.breakpoint.mdAndUp },on:{"click":function($event){_vm.grade10Dialog = true}}},[_c('v-img',{attrs:{"src":require("@/assets/enroll/khoi-10.svg"),"width":"160px"}}),_c('div',{staticClass:"btn-title title--text mt-6"},[_vm._v(" Thông tin tuyển sinh Lớp 10 ")])],1)],1)],1),_c('GradeEnrollDialog',{attrs:{"title":"Thông tin tuyển sinh khối lớp 6","src":(_vm.$baseUrl + "Khoi6.html"),"state":_vm.grade6Dialog},on:{"closeDialog":_vm.toggleGrade6Dialog}}),_c('GradeEnrollDialog',{attrs:{"title":"Thông tin tuyển sinh khối lớp 10","src":(_vm.$baseUrl + "Khoi10.html"),"state":_vm.grade10Dialog},on:{"closeDialog":_vm.toggleGrade10Dialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }