<template>
  <div class="pa-6">
    <DocumentDetailDialog :state="dialog" :documentId="selectedDocumentId" @closeDialog="dialog = !dialog" />
    <ConfirmExamResultDialog
      :state="confirmResultDialog"
      :importedDocuments="importedDocuments"
      grade="Khối 10"
      @closeDialog="closeConfirmDialog"
      @updateResult="onUpdateResultDocument"
    />
    <div class="d-flex justify-space-between align-center mb-6">
      <div class="component-title">Kết quả thi Khối 10</div>
      <div class="d-flex flex-center">
        <JsonExcel
          :data="updatedCVs"
          :fields="json_fields"
          :before-generate="toggleLoadingScreen(true)"
          :before-finish="toggleLoadingScreen(false)"
          type="xlsx"
          worksheet="Hồ sơ"
          name="ho-so-khoi-10.xls"
        >
          <v-btn color="admin" width="167px" outlined dark>
            <v-icon left>mdi-file-excel-outline</v-icon>Xuất Excel
          </v-btn>
        </JsonExcel>
      </div>
    </div>
    <div class="d-flex justify-end align-center mb-6" v-if="user.department === 'both'">
      <JsonExcel
        :data="[{code: '000001', name: 'Nguyen Van A', examTotalMark: '10', passExamText: 'Đã trúng tuyển'}]"
        :fields="example_json_fields"
        type="xls"
        worksheet="Hồ sơ"
        name="kq-trung-tuyen-k10.xls"
      >
        <v-btn color="admin" width="167px" outlined dark>
          <v-icon left>mdi-file-excel-outline</v-icon>Xuất Excel mẫu
        </v-btn>
      </JsonExcel>
      <v-btn color="admin" dark class="elevation-0 ml-6" width="167px" :loading="isSelecting" @click="onButtonClick">
        <v-icon left>mdi-upload</v-icon>Upload Excel
        <input
          ref="uploader"
          type="file"
          class="d-none"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          @change="onFileChanged"
        />
      </v-btn>
    </div>
    <Grade10ExamTestResult v-if="user.department === 'both'" />

    <v-card class="pa-6 elevation-1 mb-6">
      <DocumentFilter @onFilterChanged="onFilterChanged" />
    </v-card>

    <v-card class="elevation-1">
      <Grade10ResultTable ref="grade6Result" @onDocumentDetail="onDocumentDetail" />
    </v-card>
  </div>
</template>

<script>
const schema = {
  'Mã hồ sơ': {
    prop: 'code',
    type: String
  },
  'Họ và tên': {
    prop: 'name',
    type: String
  },
  'Điểm xét tuyển': {
    prop: 'examTotalMark',
    type: String
  },
  'Kết quả trúng tuyển': {
    prop: 'passExamText',
    type: String
  }
}
/* eslint-disable no-unused-vars */
import {mapActions, mapGetters} from 'vuex'
import DocumentFilter from './DocumentFilter'
import Grade10ResultTable from './Grade10ResultTable'
import DocumentDetailDialog from './DocumentDetailDialog'
import ConfirmExamResultDialog from './ConfirmExamResultDialog'
import Grade10ExamTestResult from './Grade10ExcelTest/ExcelAction.vue'
import readXlsxFile from 'read-excel-file'
import JsonExcel from 'vue-json-excel'
import moment from 'moment'
import {chunk, get} from 'lodash'

export default {
  components: {
    DocumentFilter,
    Grade10ResultTable,
    JsonExcel,
    DocumentDetailDialog,
    ConfirmExamResultDialog,
    Grade10ExamTestResult
  },
  props: {
    role: String
  },
  data() {
    return {
      dialog: false,
      selectedDocumentId: '',
      confirmResultDialog: false,
      importedDocuments: [],
      isSelecting: false,
      selectedFile: null,
      updatedCVs: [],
      json_fields: {
        'Mã hồ sơ': 'code',
        'Trạng thái': {
          field: 'status',
          callback: value => {
            if (value === 'submitted') return 'Đã nộp'
            else if (value === 'created') return 'Vừa tạo'
            else return 'Đang khai'
          }
        },
        Khối: {
          field: 'type',
          callback: value => {
            if (value === 'Khối 6') return '6'
            else return '10'
          }
        },
        'Tạo lúc': {
          field: 'createdAt',
          callback: value => {
            return moment(value).format('DD/MM/YYYY HH:mm:ss')
          }
        },
        'Cập nhật lúc': {
          field: 'updatedAt',
          callback: value => {
            return moment(value).format('DD/MM/YYYY HH:mm:ss')
          }
        },
        'Cơ sở': {
          field: 'department',
          callback: value => {
            if (value === 'unset') return 'Chưa có thông tin'
            else return value
          }
        },
        'Họ và tên': 'name',
        'Ngày sinh': {
          field: 'dob',
          callback: value => {
            if (value) return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
            return ''
          }
        },
        'Giới tính': {
          field: 'gender',
          callback: value => {
            return value === 'female' ? 'Nữ' : 'Nam'
          }
        },
        'Mã học sinh': 'studentId',
        'Trường từng học': 'school',
        'Thành phố': 'city',
        'Thông tin người khai': {
          field: 'parent',
          callback: value => {
            return (
              `Họ và tên: ${value.parentName || ''}\n` +
              `Số điện thoại: ${value.parentPhone || ''}\n` +
              `Số chứng minh thư: ${value.parentResidentID || ''}\n` +
              `Nghề nghiệp: ${value.parentJob || ''}\n` +
              `Địa chỉ: ${value.parentAddress || ''}`
            )
          }
        },
        'Thông tin vợ(chồng) người khai': {
          field: 'otherParent',
          callback: value => {
            return (
              `Họ và tên: ${value.otherParentName || ''}\n` +
              `Số điện thoại: ${value.otherParentPhone || ''}\n` +
              `Số chứng minh thư: ${value.otherParentResidentID || ''}\n` +
              `Nghề nghiệp: ${value.otherParentJob || ''}\n` +
              `Địa chỉ: ${value.otherParentAddress || ''}`
            )
          }
        },
        'Nguyện vọng 1': {
          field: 'expectation1',
          callback: value => {
            if (value.clazz) return value.clazz
            else if (value.school) return value.school
            else return ''
          }
        },
        'Nguyện vọng 2': {
          field: 'expectation2',
          callback: value => {
            if (value.clazz) return value.clazz
            else if (value.school) return value.school
            else return ''
          }
        },
        'Nguyện vọng 3': {
          field: 'expectation3',
          callback: value => {
            if (value.clazz) return value.clazz
            else if (value.school) return value.school
            else return ''
          }
        },
        'Kết quả học tập': {
          field: 'studyRecord',
          callback: value => {
            if (value && value.grade6Math) {
              return (
                `Lớp 6:\n` +
                `Toán: ${value.grade6Math}\n` +
                `Văn: ${value.grade6Literature}\n` +
                `Anh: ${value.grade6English}\n` +
                `Lớp 7:\n` +
                `Toán: ${value.grade7Math}\n` +
                `Văn: ${value.grade7Literature}\n` +
                `Anh: ${value.grade7English}\n` +
                `Lớp 8:\n` +
                `Toán: ${value.grade8Math}\n` +
                `Văn: ${value.grade8Literature}\n` +
                `Anh: ${value.grade8English}\n` +
                `Lý: ${value.grade8Physics}\n` +
                `Hóa: ${value.grade8Chemistry}\n` +
                `Lớp 9:\n` +
                `Toán: ${value.grade9Math}\n` +
                `Văn: ${value.grade9Literature}\n` +
                `Anh: ${value.grade9English}\n` +
                `Lý: ${value.grade9Physics}\n` +
                `Hóa: ${value.grade9Chemistry}\n`
              )
            } else return ''
          }
        },
        'Thành tích nổi bật': 'studyRecord.achievements',
        'Diện tuyển thẳng': 'studyRecord.recuitingStraightType',
        'Nguyện vọng Ban': {
          field: 'ltvExamResult',
          callback: value => {
            return (
              `Nguyện vọng 1: ${value.groupExpectation1 || ''}\n` + `Nguyện vọng 2: ${value.groupExpectation2 || ''}`
            )
          }
        },
        'Điểm thi thử lần 4': {
          callback: value => {
            const isParticipated = get(value, 'data.isTestExamShown', false)
            if (!isParticipated) return 'Không tham dự'
            const testMath = get(value, 'ltvExamResult.testMath', '')
            const testEnglish = get(value, 'ltvExamResult.testEnglish', '')
            const testLiterature = get(value, 'ltvExamResult.testLiterature', '')
            const testTotalMark = get(value, 'ltvExamResult.testTotalMark', '')
            return `Toán: ${testMath}<br>Văn: ${testLiterature}<br>Anh: ${testEnglish}<br>Tổng: ${testTotalMark}`
          }
        },
        'Số báo danh dự thi': 'ltvExamResult.studentExamID',
        'Điểm thi Toán': 'ltvExamResult.examMath',
        'Điểm thi Văn': 'ltvExamResult.examLiterature',
        'Điểm thi Anh': 'ltvExamResult.examEnglish',
        'Điểm cộng': 'ltvExamResult.priorityMark',
        'Điểm xét tuyển': 'ltvExamResult.examTotalMark',
        'Kết quả trúng tuyển': {
          field: 'ltvExamResult',
          callback: value => {
            const type = value.passExamType
            switch(type) {
              case 'pass': return 'Đã trúng tuyển'
              case 'fail': return 'Không trúng tuyển'
              case 'reserve': return 'Dự khuyết'
              default: return ''
            }
          }
        }
      },
      example_json_fields: {
        'Mã hồ sơ': 'code',
        'Họ và tên': 'name',
        'Điểm xét tuyển': 'examTotalMark',
        'Kết quả trúng tuyển': 'passExamText'
      }
    }
  },
  watch: {
    CVs(newValue) {
      this.updateCVs(newValue)
    }
  },
  computed: {
    ...mapGetters('cv', ['CVs', 'CV']),
    ...mapGetters('auth', ['user', 'isAuthenticated'])
  },
  methods: {
    ...mapActions('cv', ['fetchCVs', 'fetchCV', 'updateCV']),
    async exportExcelTemplate() {
      this.$loading.active = true
      window.location.href = `${this.$baseUrl}grade10-exam-result.xlsx`
      this.$loading.active = false
    },
    async onFilterChanged(data) {
      this.$loading.active = true
      await this.fetchCVs({
        ...data,
        _sort: 'updatedAt:DESC'
      })
      this.$loading.active = false
    },
    toggleLoadingScreen(data) {
      this.$loading.active = data
    },
    onButtonClick() {
      this.isSelecting = true
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false
        },
        {once: true}
      )

      this.$refs.uploader.click()
    },
    onDocumentDetail(data) {
      this.dialog = true
      this.selectedDocumentId = data
    },
    updateCVs(data) {
      this.updatedCVs = data.map(cv => {
        return {
          ...cv,
          parent: {
            parentName: cv.parentName,
            parentPhone: cv.parentPhone,
            parentAddress: cv.parentAddress,
            parentJob: cv.parentJob,
            parentResidentID: cv.parentResidentID
          },
          otherParent: {
            otherParentName: cv.otherParentName,
            otherParentPhone: cv.otherParentPhone,
            otherParentAddress: cv.otherParentAddress,
            otherParentJob: cv.otherParentJob,
            otherParentResidentID: cv.otherParentResidentID
          }
        }
      })
    },
    closeConfirmDialog() {
      this.isSelecting
      this.confirmResultDialog = false
      this.$refs.uploader.value = null
    },
    async onFileChanged(e) {
      this.$loading.active = true
      this.selectedFile = e.target.files[0]
      let readFileResult
      try {
        readFileResult = await readXlsxFile(this.selectedFile, {
          schema
        })
      } catch (error) {
        console.log(error)
        this.$alert.error('File không có định dạng .xlsx hoặc có dữ liệu không hợp lệ (tham khảo mẫu file)')
        this.$loading.active = false
        return
      }
      if (readFileResult.errors.length !== 0) {
        const error = readFileResult.errors[0]
        this.$alert.error(`Lỗi Dòng ${error.row} - Cột ${error.column} - ${error.value}: ${error.error}`)
        this.$loading.active = false
        return
      } else {
        const results = readFileResult.rows
        this.importedDocuments = results
        this.confirmResultDialog = true
      }
      this.$loading.active = false
    },
    async onUpdateResultDocument(results) {
      const examResultGroups = chunk(results, 5)
      for (let i = 0; i < examResultGroups.length; i++) {
        try {
          const examResults = examResultGroups[i]
          this.$refs.uploader.value = null
          const promises = results.map(async result => {
            if (result.department !== this.user.department && this.user.department !== 'both') return
            const existingCV = await this.fetchCV({
              code: result.code,
              type: 'Khối 10'
            })
            if (!existingCV) return
            let query = {
              code: result.code,
              submitType: 'update-exam-result',
              ltvExamResult: {...existingCV.ltvExamResult},
              userPhone: this.user.username
            }
            if (result.passExamText) {
              if (result.passExamText.includes('Đã trúng tuyển')) query.ltvExamResult.passExamType = 'pass'
              else if (result.passExamText.includes('Không trúng tuyển')) query.ltvExamResult.passExamType = 'fail'
              else if (result.passExamText.includes('Dự khuyết')) query.ltvExamResult.passExamType = 'reserve'
            }
            await this.updateCV(query)
          })
          await Promise.all(promises)
        } catch (error) {
          this.$alert.error(error)
        }
        this.$alert.success('Đã cập nhật thông tin điểm của tất cả thí sinh thành công!')
        this.confirmResultDialog = false
        await this.$refs.grade6Result.refresh({
          _sort: 'updatedAt:DESC'
        })
      }
    }
  },
  created() {
    this.updateCVs(this.CVs)
  }
}
/* eslint-enable no-unused-vars */
</script>

<style scoped>
.chip-width {
  min-width: 82px;
  justify-content: center;
}
.component-title {
  color: #0d47a1;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}
</style>
