var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._b({attrs:{"item-key":"id","headers":_vm.headers,"loading":_vm.loading,"items":_vm.documents,"items-per-page":10,"disable-sort":_vm.$vuetify.breakpoint.smAndDown,"footer-props":{ 'items-per-page-text': 'Số Hồ Sơ một trang' }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(items){return [_vm._v(" Hồ sơ thứ "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" trên tổng "+_vm._s(items.itemsLength)+" Hồ sơ ")]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getCode")(item))+" ")]}},{key:"item.department",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getDepartment")(item))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getStudent")(item))+" ")]}},{key:"item.entranceMark",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getEntranceMark")(item))+" ")]}},{key:"item.examPass",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getColor(item)},[_vm._v(_vm._s(_vm._f("isPassExam")(item)))])]}}],null,true)},'v-data-table',this.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }