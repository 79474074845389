var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',_vm._b({attrs:{"item-key":"id","headers":_vm.headers,"loading":_vm.loading,"items":_vm.CVs,"items-per-page":10,"disable-sort":_vm.$vuetify.breakpoint.smAndDown,"footer-props":{'items-per-page-text': 'Số Hồ Sơ một trang'}},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(items){return [_vm._v(" Hồ sơ thứ "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" trên tổng "+_vm._s(items.itemsLength)+" Hồ sơ ")]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"document-btn text-none text-decoration-underline px-0",attrs:{"color":"primary","plain":""},on:{"click":function($event){return _vm.onDocumentClick(item.id)}}},[_vm._v(" "+_vm._s(_vm._f("getCode")(item))+" ")])]}},{key:"item.department",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getDepartment")(item))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getStudent")(item))+" ")]}},{key:"item.dob",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getStudentDob")(item))+" ")]}},{key:"item.examMathMark",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getExamMathMark")(item))+" ")]}},{key:"item.examLiterature",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getExamLiterature")(item))+" ")]}},{key:"item.examEnglish",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getExamEnglish")(item))+" ")]}},{key:"item.priorityMark",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getPriorityMark")(item))+" ")]}},{key:"item.totalMark",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getTotalMark")(item))+" ")]}},{key:"item.expectation1",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getExpectation1(item))+" ")]}},{key:"item.expectation2",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getExpectation2(item))+" ")]}},{key:"item.expectation3",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getExpectation3(item))+" ")]}},{key:"item.examPass",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getColor(item)},[_vm._v(_vm._s(_vm._f("isPassExam")(item)))])]}}],null,true)},'v-data-table',this.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }