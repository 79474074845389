<template>
  <v-data-table
    item-key="id"
    :headers="headers"
    :loading="loading"
    :items="documents"
    :items-per-page="10"
    :disable-sort="$vuetify.breakpoint.smAndDown"
    :footer-props="{ 'items-per-page-text': 'Số Hồ Sơ một trang' }"
    v-bind="this.$attrs"
  >
    <template v-slot:[`footer.page-text`]="items">
      Hồ sơ thứ {{ items.pageStart }} - {{ items.pageStop }} trên tổng
      {{ items.itemsLength }} Hồ sơ
    </template>
    <template v-slot:[`item.code`]="{ item }">
      {{ item | getCode }}
    </template>
    <template v-slot:[`item.department`]="{ item }">
      {{ item | getDepartment }}
    </template>
    <template v-slot:[`item.name`]="{ item }">
      {{ item | getStudent }}
    </template>
    <template v-slot:[`item.entranceMark`]="{ item }">
      {{ item | getEntranceMark }}
    </template>
    <template v-slot:[`item.examPass`]="{ item }">
      <span :class="getColor(item)">{{ item | isPassExam }}</span>
    </template>
  </v-data-table>
</template>

<script>
/* eslint-disable no-unused-vars */

const originHeaders = [
  {
    text: "Mã hồ sơ",
    value: "code",
    align: "left",
    sortable: false,
    show: true,
  },
  {
    text: "Họ và tên",
    value: "name",
    align: "left",
    sortable: false,
    show: true,
  },
  {
    text: "Điểm xét tuyển",
    value: "entranceMark",
    align: "left",
    sortable: false,
    show: true,
  },
  {
    text: "Kết quả trúng tuyển",
    value: "examPass",
    align: "left",
    sortable: false,
    show: true,
  },
];

import { mapActions, mapGetters } from "vuex";
import { get } from "lodash";
import moment from "moment";

export default {
  props: {
    documents: Array,
  },
  computed: {
    ...mapGetters("cv", ["CVs", "CV"]),
    ...mapGetters("auth", ["user"]),
  },
  data() {
    return {
      loading: false,
      headers: originHeaders,
    };
  },
  methods: {
    ...mapActions("cv", ["fetchCVs", "fetchCV", "updateCV"]),
    async refresh(query) {
      this.loading = true;
      await this.fetchCVs({
        ...query,
        type: "Khối 10",
      });
      this.loading = false;
    },
    onDocumentClick(documentId) {
      this.$emit("onDocumentDetail", documentId);
    },
    search() {},
    getColor(item) {
      if (item.passExamText && item.passExamText != "") {
        if (item.passExamText.includes("Đã trúng tuyển"))
          return "success--text";
        else if (item.passExamText.includes("Không")) return "error--text";
        return "";
      }
      return "";
    },
    getExpectation1(item) {
      if (item.groupExpectation1) return item.groupExpectation1;
      return "---";
    },
    getExpectation2(item) {
      if (item.groupExpectation2) return item.groupExpectation2 || "---";
      return "---";
    },
  },
  filters: {
    getStudent: (item) => {
      return get(item, "name", "---");
    },
    getDepartment: (item) => {
      if (!item.department || item.department === "unset") return "---";
      else return item.department;
    },
    getCode: (item) => {
      return get(item, "code", "---");
    },
    getEntranceMark: (item) => {
      return get(item, 'examTotalMark', '---')
    },
    isPassExam: (item) => {
      return get(item, "passExamText", "---");
    },
  },
};
/* eslint-enable no-unused-vars */
</script>
