var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._b({attrs:{"item-key":"id","headers":_vm.headers,"loading":_vm.loading,"items":_vm.users,"items-per-page":10,"disable-sort":_vm.$vuetify.breakpoint.smAndDown,"footer-props":{ 'items-per-page-text': 'Số Mã hồ sơ một trang' }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(items){return [_vm._v(" Tài khoản thứ "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" trên tổng "+_vm._s(items.itemsLength)+" Tài khoản ")]}},{key:"item.username",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getUsername")(item))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getCreatedAt")(item))+" ")]}},{key:"item.isConfirmedOTP",fn:function(ref){
var item = ref.item;
return [(item.isConfirmedOTP)?_c('v-chip',{staticStyle:{"width":"120px"},attrs:{"color":"success d-flex justify-center","label":""}},[_vm._v("Đã kích hoạt")]):_c('v-chip',{staticStyle:{"width":"120px"},attrs:{"color":"gray d-flex justify-center","label":""}},[_vm._v("Chưa kích hoạt")])]}},{key:"item.SMSNum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getSMSNum")(item))+" ")]}},{key:"item.documents",fn:function(ref){
var item = ref.item;
return [(_vm.loadings[("" + (item.username))])?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_c('div',{staticClass:"d-flex align-center",class:{'justify-center': item.cvs.length === 0}},[(item.cvs.length === 0)?_c('div',[_vm._v(" Chưa tạo hồ sơ ")]):_c('v-chip-group',{attrs:{"column":""}},_vm._l((item.cvs),function(document){return _c('v-chip',{key:document.id,attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(document.code)+" ")])}),1)],1)]}}],null,true)},'v-data-table',this.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }