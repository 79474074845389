var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0",attrs:{"width":"100%"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-divider',{staticClass:"py-2"}):_vm._e(),_c('v-card-title',{staticClass:"card-title mb-2"},[_vm._v("Chọn cơ sở")]),_c('v-card-subtitle',{staticClass:"card-subtitle"},[_vm._v(" Xin quý phụ huynh lưu ý trước khi xác nhận cơ sở đăng ký cho con: Hồ sơ đăng ký tại cơ sở nào sẽ chỉ được thi và nhập học tại cơ sở đó. ")]),_c('v-card-text',{staticClass:"py-6",class:{
      'd-flex': _vm.$vuetify.breakpoint.mdAndUp,
      'd-flex flex-column align-center': _vm.$vuetify.breakpoint.smOnly
    }},[_c('v-card',{staticClass:"elevation-0",class:{
        'selected-card': _vm.department === 'Cơ sở A',
        'cursor-default': _vm.documentStep !== 1 && !_vm.isEditing,
        'mr-8': _vm.$vuetify.breakpoint.mdAndUp,
        'mb-8': _vm.$vuetify.breakpoint.smAndDown
      },staticStyle:{"max-width":"365px"},attrs:{"color":"#F8F8F8","disabled":_vm.documentStep !== 1 && !_vm.isEditing}},[_c('v-card-title',{staticClass:"item-title justify-center pa-6",class:{
          'item-text-color': _vm.department === 'Cơ sở A' || _vm.department === '',
          'disable-item-text-color': _vm.department !== 'Cơ sở A' && _vm.department !== ''
        }},[_vm._v(" Cơ sở A ")]),_c('hr',{staticClass:"solid",class:{
          'item-text-color': _vm.department === 'Cơ sở A' || _vm.department === '',
          'disable-item-text-color': _vm.department !== 'Cơ sở A' && _vm.department !== ''
        }}),_c('ul',{staticClass:"item-content pt-4 pb-6 pr-6 pl-10"},[_c('li',[_vm._v(" Địa chỉ: Số 35 Đinh Núp, Phường Trung Hòa, Quận Cầu Giấy, Hà Nội ")]),_c('li',[_vm._v("Hotline 1: 0242.215.5985")]),_c('li',[_vm._v("Hotline 2: 0246.663.8338")])]),_c('v-card-actions',{staticClass:"d-flex justify-center align-center pb-4"},[_c('v-btn',{staticClass:"text-none elevation-0 card-action-button",attrs:{"color":"primary","disabled":_vm.documentStep !== 1 && !_vm.isEditing,"large":"","outlined":""},on:{"click":function () { return _vm.chooseFacility(true); }}},[_c('span',[_vm._v("Chọn")])])],1)],1),_c('v-card',{staticClass:"elevation-0",class:{
        'selected-card': _vm.department === 'Cơ sở 1',
        'cursor-default': _vm.documentStep !== 1 && !_vm.isEditing
      },staticStyle:{"max-width":"365px"},attrs:{"color":"#F8F8F8","disabled":_vm.documentStep !== 1 && !_vm.isEditing}},[_c('v-card-title',{staticClass:"item-title justify-center pa-6",class:{
          'item-text-color': _vm.department === 'Cơ sở 1' || _vm.department === '',
          'disable-item-text-color': _vm.department !== 'Cơ sở 1' && _vm.department !== ''
        }},[_vm._v(" Cơ sở 1 ")]),_c('hr',{staticClass:"solid",class:{
          'item-text-color': _vm.department === 'Cơ sở 1' || _vm.department === '',
          'disable-item-text-color': _vm.department !== 'Cơ sở 1' && _vm.department !== ''
        }}),_c('ul',{staticClass:"item-content pt-4 pb-6 pr-6 pl-10"},[_c('li',[_vm._v("Địa chỉ: Yên Xá, Tân Triều, Thanh Trì, Hà Nội")]),_c('li',[_vm._v("Hotline: 0243.568.2603")])]),_c('v-card-actions',{staticClass:"d-flex justify-center align-center pb-4"},[_c('v-btn',{staticClass:"text-none elevation-0 card-action-button",attrs:{"color":"primary","disabled":_vm.documentStep !== 1 && !_vm.isEditing,"large":"","outlined":""},on:{"click":function () { return _vm.chooseFacility(false); }}},[_c('span',[_vm._v("Chọn")])])],1)],1)],1),_c('hr',{staticClass:"dashed"}),_c('v-card-actions',{staticClass:"d-flex justify-end pt-6 px-0"},[(_vm.isAdminPreview && _vm.isEditing)?_c('v-btn',{staticClass:"px-6 py-3 mr-6 text-none",attrs:{"color":"primary","disabled":_vm.department !== 'Cơ sở A' && _vm.department !== 'Cơ sở 1',"outlined":"","large":""},on:{"click":function($event){return _vm.saveDraft()}}},[_c('v-icon',[_vm._v(" mdi-content-save ")]),_c('span',{staticClass:"ml-2"},[_vm._v("Lưu")])],1):_vm._e(),(_vm.isUpdatable && !_vm.isEditing)?_c('v-btn',{staticClass:"py-3 mr-6 text-none",class:{'px-6': _vm.$vuetify.breakpoint.mdAndUp},attrs:{"color":"primary","outlined":"","large":""},on:{"click":_vm.onEdit}},[_c('span',[_vm._v("Chỉnh sửa")])]):_vm._e(),(_vm.isAdminPreview)?_c('v-btn',{staticClass:"px-6 py-3 text-none elevation-0",attrs:{"color":"primary","disabled":_vm.department !== 'Cơ sở A' && _vm.department !== 'Cơ sở 1',"large":""},on:{"click":_vm.nextStep}},[_c('span',[_vm._v("Tiếp theo")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }