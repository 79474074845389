<template>
  <div>
    <div class="section-label pb-6">Nguyện vọng {{ itemIndex }}</div>
    <v-form ref="expectation-form">
      <v-row class="my-0">
        <v-col class="py-0">
          <div class="field-label">
            Con tôi đã đăng ký nguyện vọng {{ itemIndex }} vào lớp 10 trường THPT
            <span style="color: red" v-if="isEditable">*</span>
          </div>
          <v-text-field
            placeholder="VD: Trường THPT..."
            v-model="school"
            type="text"
            color="primary"
            v-if="isEditable"
            @keyup.enter="submit"
            :rules="[$rules.required]"
            outlined
            validate-on-blur
          />
          <div class="info-label mt-2 mb-6" v-if="isInformative">
            {{ school || 'Chưa có thông tin' }}
          </div>
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col class="py-0" cols="12" xs="12" sm="12" md="6">
          <div class="field-label">
            Quận (Huyện)
            <span style="color: red" v-if="isEditable">*</span>
          </div>
          <v-text-field
            placeholder="VD: Ba Đình"
            v-model="district"
            type="text"
            color="primary"
            v-if="isEditable"
            @keyup.enter="submit"
            :rules="[$rules.required]"
            outlined
            validate-on-blur
          />
          <div class="info-label mt-2 mb-6" v-if="isInformative">
            {{ district || 'Chưa có thông tin' }}
          </div>
        </v-col>
        <v-col class="py-0" cols="12" xs="12" sm="12" md="6">
          <div class="field-label">
            Thành phố
            <span style="color: red" v-if="isEditable">*</span>
          </div>
          <v-text-field
            placeholder="VD: Hà Nội"
            v-model="city"
            type="text"
            color="primary"
            v-if="isEditable"
            @keyup.enter="submit"
            :rules="[$rules.required]"
            outlined
            validate-on-blur
          />
          <div class="info-label mt-2 mb-6" v-if="isInformative">
            {{ city || 'Chưa có thông tin' }}
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
export default {
  props: {
    isEditable: {
      type: Boolean,
      default: false
    },
    isInformative: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => ({})
    },
    itemIndex: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      school: '',
      district: '',
      city: ''
    }
  },
  methods: {
    handleItem(item) {
      this.school = item.school || ''
      this.district = item.district || ''
      this.city = item.city || ''
    },
    validate() {
      return this.$refs['expectation-form'].validate()
    },
    getData(isSkipValidation = false) {
      const result = {school: this.school, district: this.district, city: this.city}
      if (isSkipValidation) return result
      const isValid = this.validate()
      if (!isValid) {
        this.$alert.error('Xin vui lòng điền hết các thông tin bắt buộc')
        return false
      }
      return result
    }
  },
  watch: {
    item: {
      handler(item) {
        if (item) this.handleItem(item)
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
<style scoped>
.field-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #797979;
  margin-bottom: 4px;
}
.section-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #3e3e3c;
}
.info-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3e3e3c;
}
</style>
