<template>
  <v-container class="overflow-auto pa-0 relative" fluid fill-height>
    <v-row class="d-flex align-center" no-gutters>
      <v-col xs="12" sm="12" md="6" v-if="$vuetify.breakpoint.mdAndUp">
        <v-img
          src="@/assets/homepage/home-bg.png"
          class="bg-image"
          :class="{'bg-image__desktop': $vuetify.breakpoint.lgAndUp, 'bg-image__laptop': $vuetify.breakpoint.mdOnly}"
        ></v-img>
      </v-col>
      <v-col xs="12" sm="12" md="6" :class="{'pt-6': $vuetify.breakpoint.smAndDown}">
        <div
          v-if="
            ($vuetify.breakpoint.smAndDown && systemTime.checkSystemTime['open-document']) ||
              ($vuetify.breakpoint.smAndDown && isDevelopmentBuild)
          "
          style="width: 100%"
          class="d-flex justify-end"
          :class="{'pr-5': !$vuetify.breakpoint.smAndDown}"
        >
          <GuestToolbar v-if="isGuestBar" :isOpen="isGuestToolbarOpen" />
          <UserToolbar v-if="!isGuestBar" :isOpen="systemTime.checkSystemTime['open-document'] || isDevelopmentBuild" />
        </div>
        <v-row
          class="items-container d-flex justify-center align-center mx-auto"
          :class="{
            'mobile-container ma-4': $vuetify.breakpoint.xsOnly,
            'tablet-container my-4': $vuetify.breakpoint.smOnly,
            'laptop-container': $vuetify.breakpoint.mdOnly,
            'desktop-container': $vuetify.breakpoint.lgAndUp
          }"
        >
          <v-card
            v-for="n in 4"
            class="elevation-0 mb-3"
            :key="n"
            :class="{
              'mobile-menu-blog': $vuetify.breakpoint.xsOnly,
              'tablet-menu-blog': $vuetify.breakpoint.smOnly,
              'laptop-menu-blog': $vuetify.breakpoint.mdOnly,
              'desktop-menu-blog': $vuetify.breakpoint.lgAndUp,
              'align-self-start': n === 4 || n === 6,
              'mr-3': n % 2 !== 0,
              'mb-3': n < 3
            }"
            style="background-color: transparent; border: 1px solid #D2D3DB;"
            @click="getBtnEvent(n)"
          >
            <v-card-text class="pa-6">
              <v-img :src="getImageSrc(n)" class="mx-auto" :width="94" :height="94" />
            </v-card-text>
            <v-divider class="mx-2" />
            <v-card-actions class="px-2 py-6 d-flex justify-center">
              <div
                class="justify-center menu-title text-center text-body-2"
                v-html="$vuetify.breakpoint.xsOnly ? menu[n - 1].mobile : menu[n - 1].title"
              ></div>
            </v-card-actions>
            <!-- <hr class="mt-2" style="width:100%;" /> -->
          </v-card>
        </v-row>
        <div v-if="$vuetify.breakpoint.mdAndUp" style="height: 52px"></div>
        <Footer />
      </v-col>
      <v-col xs="12" sm="12" md="6" v-if="$vuetify.breakpoint.smAndDown">
        <v-img src="@/assets/homepage/home-bg.png" style="margin-bottom: 72px;"></v-img>
      </v-col>
    </v-row>
    <EnrollDialog :state="enrollDialog" @closeEnroll="toggleEnrollDialog" />
    <FacilityDialog :state="facilityDialog" @closeFacility="toggleFacilityDialog" />
    <IntroDialog
      title="Giới thiệu chung"
      :state="introDialog"
      :src="`${$baseUrl}GTC.html`"
      @closeDialog="toggleIntroDialog(false)"
    />
    <IntroDialog
      title="Hoạt động ngoại khóa"
      :state="activityDialog"
      :src="`${$baseUrl}HoatDong.html`"
      @closeDialog="toggleActivityDialog(false)"
    />
    <IntroDialog
      title="Hướng dẫn khai Hồ sơ tuyển sinh"
      :state="tutorialDialog"
      :src="`${$baseUrl}HuongDanTuyenSinh.html`"
      @closeDialog="toggleTutorialDialog(false)"
    />
    <CountdownTimer
      :state="countdownDialog"
      :date="systemTime.systemTime['open-document']"
      @closeDialog="toggleCountdownDialog(false)"
    />
    <GradeEnrollDialog
      title="Thông tin tuyển sinh khối lớp 6"
      :src="`${$baseUrl}mobile/Khoi6.html`"
      :state="grade6Dialog"
      @closeDialog="grade6Dialog = false"
    />
    <GradeEnrollDialog
      title="Thông tin tuyển sinh khối lớp 10"
      :src="`${$baseUrl}mobile/Khoi10.html`"
      :state="grade10Dialog"
      @closeDialog="grade10Dialog = false"
    />
    <IntroDialog
      title="Hướng dẫn đăng ký tuyển sinh 2024-2025"
      :state="appDialog"
      :src="`${$baseUrl}mobile/HuongDanTuyenSinh.html`"
      @closeDialog="() => (appDialog = false)"
    />
    <div class="message-fab-wrapper" :class="{'tablet-screen-and-down': $vuetify.breakpoint.smAndDown}" v-show="fab">
      <div class="bubble">
        <div
          class="text-subtitle-2 primary--text"
          style="cursor: pointer;"
          @click="() => openLink('https://www.facebook.com/LTVHN')"
        >
          Cơ sở A - Cầu Giấy
        </div>
        <div
          class="text-subtitle-2 primary--text"
          style="cursor: pointer;"
          @click="() => openLink('https://www.facebook.com/ltvtantrieu')"
        >
          Cơ sở 1 - Tân Triều
        </div>
      </div>
    </div>
    <div class="button-fab-wrapper" :class="{'tablet-screen-and-down': $vuetify.breakpoint.smAndDown}">
      <v-btn fab color="#255CC2" @click="fab = !fab">
        <v-icon color="white">mdi-facebook-messenger</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import GuestToolbar from '@/components/layout/GuestToolbar.vue'
import UserToolbar from '@/components/layout/UserToolbar.vue'
import Footer from './Footer.vue'
import EnrollDialog from '@/views/enroll/EnrollDialog.vue'
import FacilityDialog from '@/views/facility/FacilityDialog.vue'
import CountdownTimer from '@/components/basic/CountdownTimer.vue'
import IntroDialog from './IntroDialog.vue'
// import AppDialog from './AppDialog.vue'
import GradeEnrollDialog from '@/views/enroll/GradeEnrollDialog.vue'
import {mapGetters, mapActions} from 'vuex'

export default {
  components: {
    IntroDialog,
    CountdownTimer,
    GuestToolbar,
    UserToolbar,
    Footer,
    EnrollDialog,
    FacilityDialog,
    GradeEnrollDialog
    // AppDialog
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated', 'user', 'isConfirmedOTP']),
    ...mapGetters('cv', ['systemTime']),
    isGuestBar() {
      if (this.isAuthenticated && this.user) return false
      return true
    },
    isGuestToolbarOpen() {
      // return systemTime.checkSystemTime['open-document'] || isDevelopmentBuild
      return false
    },
    getImageSize() {
      if (this.$vuetify.breakpoint.smAndDown) return 100
      const maxSize = (768 - 80 - 52 - 48) / 3 - 12 * 2 - 24 - 24
      let imageSize = (this.window.height - 80 - 52 - 48) / 3 - 12 * 2 - 24
      if (imageSize >= maxSize) imageSize = maxSize
      return imageSize
    },
    isDevelopmentBuild() {
      return process.env.NODE_ENV === 'development'
    }
  },
  name: 'Home',
  methods: {
    ...mapActions('layout', [
      'setDocumentDialog',
      'setConfirmSignupDialog',
      'setSignInDialog',
      'setPhoneRegisterDialog'
    ]),
    ...mapActions('cv', ['checkSystemTime']),
    openLink(link = '') {
      window.open(link, '_blank', 'noopener noreferrer')
    },
    getImageSrc(n) {
      return this.menu[n - 1].src
    },
    getBtnEvent(n) {
      switch (n - 1) {
        case 0:
          this.grade6Dialog = true
          break
        case 1:
          this.grade10Dialog = true
          break
        case 2:
          this.appDialog = true
          break
        case 3:
          this.activityDialog = true
          break
        // case 4:
        //   this.facilityDialog = true
        //   break
        // case 5:
        //   this.activityDialog = true
        // break
        default:
          break
      }
    },
    onDocumentClick() {
      if (!this.systemTime.checkSystemTime['open-document']) {
        if (this.user && this.isAuthenticated && !this.isConfirmedOTP && process.env.NODE_ENV === 'development')
          this.setConfirmSignupDialog(true)
        else if (this.user && this.isAuthenticated && this.user.role.type === 'admin') this.setDocumentDialog(true)
        else if (process.env.NODE_ENV === 'development' && this.user && this.isAuthenticated)
          this.setDocumentDialog(true)
        else this.toggleCountdownDialog(true)
      } else if (this.user && this.isAuthenticated && this.isConfirmedOTP) this.setDocumentDialog(true)
      else if (this.user && this.isAuthenticated && !this.isConfirmedOTP) this.setConfirmSignupDialog(true)
      else this.setSignInDialog(true)
    },
    toggleCountdownDialog(data) {
      this.countdownDialog = data
    },
    toggleEnrollDialog(data) {
      this.enrollDialog = data
    },
    toggleActivityDialog(data) {
      this.activityDialog = data
    },
    toggleTutorialDialog(data) {
      this.tutorialDialog = data
    },
    toggleIntroDialog(data) {
      this.introDialog = data
    },
    toggleFacilityDialog(data) {
      this.facilityDialog = data
    },
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    }
  },
  async created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  data: () => ({
    fab: false,
    grade6Dialog: false,
    grade10Dialog: false,
    appDialog: false,
    activityDialog: false,
    enrollDialog: false,
    facilityDialog: false,
    countdownDialog: false,
    introDialog: false,
    tutorialDialog: false,
    window: {
      width: 0,
      height: 0
    },
    menu: [
      {
        title: 'Thông tin tuyển sinh<br>vào lớp 6',
        src: require('@/assets/homepage/Group1.svg'),
        mobile: 'Thông tin<br>tuyển sinh vào lớp 6'
      },
      {
        title: 'Thông tin tuyển sinh<br>vào lớp 10',
        src: require('@/assets/homepage/Group2.svg'),
        mobile: 'Thông tin<br>tuyển sinh vào lớp 10'
      },
      {
        title: 'Hướng dẫn đăng ký<br>tuyển sinh 2024-2025',
        src: require('@/assets/homepage/Group3.svg'),
        mobile: 'APP<br>"Tuyển sinh LTV"'
      },
      {
        title: 'Gallery ảnh hoạt động',
        src: require('@/assets/homepage/Group4.svg'),
        mobile: 'Gallery ảnh<br>hoạt động'
      }
      // {
      //   title: "Tham quan Cơ sở Nhà trường",
      //   src: require("@/assets/homepage/Group-6.svg"),
      //   mobile:
      //     "<span class='item-mobile-title'>Tham quan <br/> Cơ sở Nhà trường</span>" +
      //     "<span class='item-desktop-title'>Tham quan Cơ sở Nhà trường</span>",
      // },
      // {
      //   title: "Hoạt động ngoại khóa",
      //   src: require("@/assets/homepage/Group-3.svg"),
      //   mobile: "Hoạt động ngoại khóa",
      // },
    ]
  })
}
</script>
<style>
.item-mobile-title {
  display: none;
}
.item-desktop-title {
  display: block;
}
@media screen and (min-width: 600px) {
  div.v-card--link:hover {
    background: rgb(116, 160, 246, 0.15) !important;
  }
}
@media screen and (max-width: 960px) {
  .item-mobile-title {
    display: block;
  }
  .item-desktop-title {
    display: none;
  }
}
</style>
<style scoped lang="scss">
.bg-image {
  position: absolute;
  object-fit: contain;
  width: 50%;
  height: 100%;
  top: 0px;
}
.bg-image__desktop {
  ::v-deep {
    .v-image__image--cover {
      background-position: 75% center !important;
    }
  }
}
.bg-image__laptop {
  ::v-deep {
    .v-image__image--cover {
      background-position: 72% center !important;
      background-size: 140% 100%;
    }
  }
}
.menu-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.mobile-menu-title {
  height: 48px;
}
.mobile-menu-blog {
  width: calc(100% / 2 - 12px * 2);
  max-width: 276px;
}
.tablet-menu-blog {
  width: calc(720px / 2 - 12px * 2);
  /* max-width: 220px; */
}
.laptop-menu-blog {
  width: calc(640px / 2 - 12px * 4);
  max-width: 220px;
}
.desktop-menu-blog {
  width: calc(640px / 2 - 12px * 4);
  /* max-width: 320px; */
}
.tablet-container {
  max-width: 720px;
}
.laptop-container {
  width: 100%;
  max-width: 600px;
  max-height: calc(100% - 80px - 52px - 12px * 2);
}
.desktop-container {
  width: 100%;
  max-width: 640px;
  max-height: calc(100% - 80px - 52px - 12px * 2);
}
.mobile-menu-title {
  height: 48px;
}
.items-container {
  /* background-image: url('../../assets/watermark.svg'); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-size: calc(100% - 48px) calc(100% - 48px);
}
.bubble {
  position: relative;
  background: #ffffff;
  color: #000000;
  font-family: Arial;
  font-size: 20px;
  line-height: 120px;
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.3));
  text-align: center;
  padding: 8px 12px;
  border-radius: 4px;
  border: #255cc2 solid 1px;
}
.bubble:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: #ffffff transparent;
  border-width: 17px 7px 0;
  bottom: -17px;
  left: 83%;
  margin-left: -7px;
}
.bubble:before {
  content: '';
  position: absolute;
  width: 0;
  z-index: 0;
  border-style: solid;
  border-color: #255cc2 transparent;
  border-width: 18px 8px 0;
  bottom: -18.5px;
  left: 83%;
  margin-left: -8px;
  display: block;
}

.button-fab-wrapper {
  position: absolute;
  bottom: 68px;
  right: 16px;
  &.tablet-screen-and-down {
    bottom: 88px;
  }
}
.message-fab-wrapper {
  position: absolute;
  bottom: 150px;
  right: 16px;
  &.tablet-screen-and-down {
    bottom: 170px;
  }
}
</style>
