<template>
  <v-data-table
    item-key="id"
    :headers="headers"
    :loading="loading"
    :items="notifications"
    :items-per-page="10"
    disable-sort
    :footer-props="{'items-per-page-text': 'Số thông báo một trang'}"
    v-bind="this.$attrs"
  >
    <template v-slot:[`footer.page-text`]="items">
      Thông báo thứ {{ items.pageStart }} - {{ items.pageStop }} trên tổng {{ items.itemsLength }} thông báo
    </template>
    <template v-slot:[`item.user`]="{item}">
      {{ (item.user && item.user.username) || '' }}
    </template>
    <template v-slot:[`item.createdAt`]="{item}">
      {{ item | getCreatedAt }}
    </template>
    <template v-slot:[`item.status`]="{item}">
      <v-icon color="green" v-if="item.status === 'success'">mdi-check-circle</v-icon>
      <v-icon color="red" v-else>mdi-close</v-icon>
    </template>
    <template v-slot:[`item.receiver`]="{item}">
      {{ item | getReceiver }}
    </template>
    <template v-slot:no-data>
      <div class="d-flex flex-column align-center justify-center pa-6">
        <div class="mt-4 text-subtitle-1">Chưa có dữ liệu</div>
      </div>
    </template>
    <template v-slot:[`item.content`]="{item}">
      <div v-html="item.content" />
    </template>
  </v-data-table>
</template>
<script>
import {mapGetters} from 'vuex'
import moment from 'moment'
moment.locale('vi')
const originHeaders = [
  {
    text: 'Tiêu đề',
    value: 'title',
    align: 'left',
    width: '20%'
  },
  {
    text: 'Nội dung',
    value: 'content',
    align: 'left',
    width: '20%'
  },
  {
    text: 'Tạo lúc',
    value: 'createdAt',
    align: 'left',
    show: true
  },
  // {
  //   text: 'Thiết bị',
  //   value: 'method',
  //   width: '10%',
  //   align: 'left',
  //   show: true
  // },
  {
    text: 'Gửi đến',
    value: 'receiver',
    align: 'left',
    show: true
  },
  // {
  //   text: 'Đối tượng',
  //   value: 'user',
  //   align: 'left',
  //   show: true
  // },
  // {
  //   text: 'Trạng thái',
  //   value: 'status',
  //   align: 'left',
  //   show: true
  // }
]
export default {
  data() {
    return {
      headers: originHeaders,
      loading: false
      // notifications: {}
    }
  },
  computed: {
    ...mapGetters({
      notifications: 'notification/notis'
    })
  },
  filters: {
    getCreatedAt(item) {
      if (item.createdAt) return moment(item.createdAt).format('DD/MM/YYYY')
      else return '---'
    },
    getStatus(item) {
      if (item.status === 'success') return 'Thành công'
      else return '---'
    },
    getReceiver(item) {
      if (item.receiver === 'all') return 'Tất cả'
      else if (item.receiver === 'reg') return 'Đã đăng ký'
      else if (item.receiver === 'reg_cv6') return 'Đã đăng ký hồ sơ tuyển sinh 6'
      else if (item.receiver === 'reg_cv10') return 'Đã đăng ký hồ sơ tuyển sinh 10'
      else if (item.receiver === 'single') return 'Đối tượng cụ thể'
    }
  }
}
</script>
