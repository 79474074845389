<template>
  <div class="document-container py-8 mx-auto">
    <v-row no-gutters>
      <v-col
        class="px-8"
        :class="{'pb-6': $vuetify.breakpoint.smAndDown}"
        style="border-right: 1px solid #e6e4eb"
        cols="12"
        xs="12"
        sm="12"
        md="4"
      >
        <v-card class="elevation-0" width="100%">
          <v-stepper class="elevation-0 py-0" v-model="step" vertical non-linear>
            <v-stepper-step
              v-for="item in $helpers.STEP_DATA_GRADE_10"
              :key="item.step"
              class="mb-4 cursor-pointer"
              :step="item.step"
              :complete="isCompleted(item.step)"
              @click="onStepClick(item.key, item.step)"
            >
              <span class="step-title mb-1">{{ item.title }}</span>
              <div class="step-subtitle" :class="getStatusColor(item.key, item.step)">
                {{ getStatus(item.key, item.step) }}
              </div>
            </v-stepper-step>
          </v-stepper>
        </v-card>
      </v-col>
      <v-col class="d-flex px-8" cols="12" xs="12" sm="12" md="8">
        <ChooseFacility
          v-if="step === 1"
          :document="document"
          :documentStep="finalStep"
          :isAdminPreview="true"
          :isUpdatable="isUpdatable"
          @nextStep="nextStep"
          @saveDraft="saveDraft"
        />
        <InfoForm
          v-if="step === 2"
          :document="document"
          :documentStep="finalStep"
          :isAdminPreview="true"
          :isUpdatable="isUpdatable"
          @nextStep="nextStep"
          @saveDraft="saveDraft"
        />
        <Grade10Expectation
          v-if="step === 5"
          :document="document"
          :documentStep="finalStep"
          :systemTime="systemTime"
          :isAdminPreview="true"
          :isUpdatable="isUpdatable"
          @nextStep="nextStep"
          @saveDraft="saveDraft"
        />
        <Grade10GroupExpectation
          v-if="step === 6"
          :document="document"
          :documentStep="finalStep"
          :systemTime="systemTime"
          :isAdminPreview="true"
          :isUpdatable="isUpdatable"
          @nextStep="nextStep"
          @saveDraft="saveDraft"
        />
        <ResultForm
          v-if="step === 4"
          :document="document"
          :documentStep="finalStep"
          :systemTime="systemTime"
          :isAdminPreview="true"
          :isUpdatable="isUpdatable"
          @nextStep="nextStep"
          @saveDraft="saveDraft"
        />
        <ExamResultForm
          v-if="step === 7"
          :document="document"
          :documentStep="finalStep"
          :isAdminPreview="true"
          :systemTime="systemTime"
          @nextStep="nextStep"
          @saveDraft="saveDraft"
          :isUpdatable="isUpdatable"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ChooseFacility from '@/views/document/ChooseFacility.vue'
import InfoForm from '@/views/document/InfoForm.vue'
import ResultForm from '@/views/document/ResultForm.vue'
import ExamResultForm from '@/views/document/ExamResultForm.vue'
import Grade10Expectation from '@/modules/cv/Grade10Expectation.vue'
import Grade10GroupExpectation from '@/modules/cv/Grade10GroupExpectation.vue'
import moment from 'moment'

export default {
  components: {
    ChooseFacility,
    InfoForm,
    ResultForm,
    ExamResultForm,
    Grade10Expectation,
    Grade10GroupExpectation
  },
  data() {
    return {
      finalStep: 6
    }
  },
  computed: {
    ...mapGetters('auth', ['user', 'isAuthenticated']),
    ...mapGetters('cv', ['step']),
    getExamResultDate() {
      if (this.systemTime.documentSystemTime)
        return moment(this.systemTime.documentSystemTime['exam-result'], 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY')
      return '21/06/2023'
    },
    isUpdatable() {
      return this.$helpers.ALLOWED_UPDATE.includes(this.user.id)
    }
  },
  props: {
    documentCode: String,
    document: Object,
    systemTime: Object
  },
  created() {
    this.setStep(1)
  },
  methods: {
    ...mapActions('cv', ['fetchCVs', 'fetchCV', 'updateCV', 'checkSystemTime', 'setStep']),
    ...mapActions('layout', ['setDocumentDialog']),
    getStatus(key, step) {
      if (step == 2) {
        const isStep2 = this.$utils.get(this.document, `data.step_2`, false)
        const isStep3 = this.$utils.get(this.document, `data.step_3`, false)
        let result = ''
        if (isStep2) result += 'Đã khai PH'
        else result += 'Đang khai PH'
        if (isStep3) result += ' | Đã khai HS'
        else result += ' | Đang khai HS'
        return result
      }
      if (this.$utils.get(this.document, `data.step_${step}`, false)) return 'Đã khai'
      return 'Đang khai'
    },
    getStatusColor(key, step) {
      if (step == 2) {
        const isStep2 = this.$utils.get(this.document, `data.step_2`, false)
        const isStep3 = this.$utils.get(this.document, `data.step_3`, false)
        if (isStep2 && isStep3) return 'success--text'
        return 'dark-gray--text'
      }
      if (this.$utils.get(this.document, `data.step_${step}`, false) == false) return 'dark-gray--text'
      return 'success--text'
    },
    isCompleted(step) {
      if (step == 2) {
        const isStep2 = this.$utils.get(this.document, `data.step_2`, false)
        const isStep3 = this.$utils.get(this.document, `data.step_3`, false)
        return isStep2 && isStep3
      }
      return this.$utils.get(this.document, `data.step_${step}`, false)
    },
    onStepClick(key, step) {
      if (!this.systemTime) return
      if (
        (!this.systemTime.checkDocumentSystemTime || !this.systemTime.checkDocumentSystemTime[key]) &&
        this.user.role.type !== 'admin'
      ) {
        if (step === this.finalStep)
          this.$alert.error(
            `Phần thông tin khai báo kết quả kỳ thi tuyển sinh vào lớp 10 của TP Hà Nội hiện giờ chưa được mở. Nhà trường sẽ mở khai báo này khi thành phố công bố kết quả kỳ thi vào lớp 10. Phụ huynh vui lòng kiểm tra tin nhắn yêu cầu khai báo điểm của nhà trường. Xin cảm ơn phụ huynh.`
          )
        else
          this.$alert.error(
            'Phần thông tin tiếp theo chưa được mở. Thời gian cụ thể Nhà trường sẽ gửi qua SMS. Phụ huynh vui lòng kiểm tra tin nhắn để nhận thông báo'
          )
        return
      }
      if (this.document.step < step && this.user.role.type !== 'admin') {
        this.$alert.error('Xin vui lòng hoàn thành bước trước')
        return
      }
      this.setStep(step)
    },
    nextStep() {
      this.setStep(this.step + 1)
      this.$loading.active = false
    },
    async saveDraft({callback, ...data}) {
      if (this.isUpdatable) {
        await this.updateCV({
          submitType: 'update-admin',
          code: this.document.code,
          userPhone: this.document.parent.username,
          ...data
        })
        if (typeof callback === 'function') callback()
      } else {
        this.$alert.error('Tài khoản của bạn không được lưu thông tin này')
      }
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.v-stepper--vertical {
  padding: 24px 0px;
}
div >>> .v-stepper--vertical .v-stepper__step__step {
  margin-right: 24px !important;
}
.v-stepper--vertical .v-stepper__step {
  padding: 0px !important;
}
.notice {
  background: rgba(255, 196, 16, 0.2);
  border-radius: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.document-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}
.document-subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #9c9c9c;
}
.step-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.step-subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}
.document-container {
  height: 100%;
  max-width: 1280px;
}

div >>> .v-stepper__step--active .v-stepper__label {
  text-shadow: none !important;
}
div >>> .v-stepper__step--active .v-stepper__label span.step-title {
  color: #0084ff;
}
</style>
