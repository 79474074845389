const STEP_DATA_GRADE_10 = [
  {
    title: 'Chọn cơ sở',
    key: 'choose-department',
    step: 1,
  },
  {
    title: 'Thông tin phụ huynh, học sinh',
    key: 'fill-info',
    step: 2,
  },
  {
    title: 'Kết quả học tập',
    key: 'study-result',
    step: 4,
  },
  {
    title: 'Nguyện vọng trường công lập',
    key: 'register-expectation',
    step: 5,
  },
  {
    title: 'Đăng ký tổ hợp môn học tự chọn',
    key: 'register-group-expectation',
    step: 6,
  },
  {
    title: 'Kết quả kỳ thi tuyển sinh vào Lớp 10',
    key: 'exam-result',
    step: 7,
  },
]
const LIST_USER_PROD = [
  '6405ac8fe86dad2ffb0346d2',
  '6405a73ae86dad2ffb0346c9',
  '6405ac12e86dad2ffb0346cf',
  '65a0f59b2365e764e9c501e3'
]
const LIST_USER_DEV = ['6412da5d9b5cb29506e5758a']
//process.env.NODE_ENV === 'development'
const ALLOWED_UPDATE = process.env.NODE_ENV === 'development' ? LIST_USER_DEV : LIST_USER_PROD

export default {
  STEP_DATA_GRADE_10,
  ALLOWED_UPDATE,
}