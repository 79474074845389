<template>
  <v-form ref="form" v-bind="this.$attrs">
    <v-divider class="py-2" v-if="$vuetify.breakpoint.smAndDown"></v-divider>
    <!-- <v-card-title class="card-title">Điểm thi</v-card-title> -->
    <v-card-subtitle class="card-title" style="padding: 24px 0px !important">
      Kết quả kỳ KSĐGNL vào lớp 6 năm học 2024 - 2025
    </v-card-subtitle>
    <v-card-text class="d-flex flex-column pa-0">
      <v-row class="my-0">
        <v-col class="py-0">
          <div class="field-label">Mã hồ sơ</div>
          <div class="info-label mt-2 mb-6" v-if="documentStep === 4">
            {{ getCodeCV }}
          </div>
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col
          class="py-0 error--text text-h6 mb-4"
          cols="12"
          xs="12"
          sm="12"
          md="5"
          v-if="document.ltvExamResult && document.ltvExamResult.examMark === 'Không làm bài'"
        >
          <div class="field-label" style="height: 42px">
            Điểm khảo sát ĐGNL
          </div>
          Không làm bài
        </v-col>
        <v-col class="py-0" cols="12" xs="12" sm="12" md="5" v-else>
          <div class="field-label" style="height: 42px">
            Điểm khảo sát ĐGNL
          </div>
          <div class="info-label error--text mb-6" v-if="documentStep === 4">
            <div class="d-flex pb-2 flex-column flex-md-row">
              <div class="info-label__title py-2 py-md-0">Môn Toán:</div>
              <div class="info-label__data py-2 py-md-0">
                {{ getExamMath }}
              </div>
            </div>
            <div class="d-flex py-2 flex-column flex-md-row">
              <div class="info-label__title py-2 py-md-0">Môn Ngữ Văn:</div>
              <div class="info-label__data py-2 py-md-0">
                {{ getExamLiterature }}
              </div>
            </div>
            <div class="d-flex py-2 flex-column flex-md-row">
              <div class="info-label__title py-2 py-md-0">Môn Tiếng Anh:</div>
              <div class="info-label__data py-2 py-md-0">{{ getExamEnglish }}</div>
            </div>
          </div>
        </v-col>
        <v-col
          class="py-0 d-flex justify-space-between"
          :class="{'flex-column': $vuetify.breakpoint.smAndDown}"
          cols="12"
          xs="12"
          sm="12"
          md="7"
        >
          <!-- <div>
            <div class="field-label" style="height: 42px">Tổng điểm Học Bạ</div>
            <div class="info-label error--text mt-2 mb-6" style="font-size: 20px" v-if="documentStep === 4">
              {{ getTotalMathLiterature }}
            </div>
          </div> -->
          <div>
            <div class="field-label" style="height: 42px">Điểm ưu tiên</div>
            <div class="info-label error--text mt-2 mb-6" style="font-size: 20px" v-if="documentStep === 4">
              {{ getPriorityMark }}
            </div>
          </div>
          <hr class="dashed mb-4" v-if="$vuetify.breakpoint.smAndDown" />
          <div>
            <div class="field-label mb-2" style="height: 42px">
              Tổng điểm xét tuyển
            </div>
            <div class="info-label error--text mt-2 mb-6" style="font-size: 24px" v-if="documentStep === 4">
              {{ getTotalMark }}
            </div>
          </div>
        </v-col>
      </v-row>
      <!-- <hr class="dashed" v-if="!isOpenDisplayResult" /> -->
      <!-- <v-card
        class="pa-6 text-justify my-6 elevation-2"
        v-if="!isOpenDisplayResult"
      >
        <div class="text-h6">Phụ huynh lưu ý:</div>
        <div>
          - Hệ thống đã được cập nhật để hiển thị <b>Tổng số câu đúng</b> và
          <b>Tỉ lệ % câu đúng</b> trong <b>Bài khảo sát ĐGNL</b>.
        </div>
        <div>
          - Hệ thống hiện đang mở chỉnh sửa điểm học bạ cho toàn bộ học sinh.
          Phụ huynh có thể khai báo thêm hoặc chỉnh sửa nội dung thông tin của
          phần này. Hạn chỉnh sửa điểm dự kiến là 00:00, ngày 18/06/2023.
        </div>
        <div>
          - Nhà trường sẽ lấy dữ liệu điểm học bạ của toàn bộ học sinh dự kiến
          vào 00:00 ngày 18/06/2023 làm căn cứ xét điểm chuẩn vào trường.
        </div>
        <div>
          - Đề nghị phụ huynh khai báo điểm chính xác trước thời gian trên để
          con có thể được cộng đủ số điểm tổng học bạ.
        </div>
      </v-card> -->
      <!-- TODO: Uncomment when display result-->
      <div v-if="isOpenDisplayResult">
        <hr class="dashed" />
        <div class="py-6" v-if="document.ltvExamResult.passExamText && document.ltvExamResult.passExamType === 'pass'">
          <div class="mx-auto" style="max-width: 250px">
            <v-img src="@/assets/pass.svg" />
          </div>
          <!-- <div class="fail-card pa-4 mt-6">
            <div>
              Chúc mừng học sinh đã xuất sắc trải qua kỳ KSĐGNL vào lớp 6 năm học 2024 - 2025 của trường THCS&THPT Lương
              Thế Vinh.
            </div>
            <div class="mt-2">
              Ban tuyển sinh sẽ nhận hồ sơ ghi danh vào
              <span class="font-weight-bold error--text">Chủ nhật, ngày 16/04/2023.</span>
            </div>
            <div class="mt-2"><span class="font-weight-bold">Thời gian làm việc của ban tuyển sinh:</span></div>
            <div class="mt-2 ml-4">Sáng: 8:00 - 11:30</div>
            <div class="mt-2 ml-4">Chiều: 13:30 - 16:00</div>
            <div class="mt-2"><span class="font-weight-bold">Hồ sơ nhập học cho con bao gồm:</span></div>
            <div class="mt-2 ml-4">- Giấy khai sinh (Bản photo)</div>
            <div class="mt-2 ml-4">- Học bạ Tiểu học (Bản photo)</div>
            <div class="mt-2 ml-4">- Lệ phí nhập học</div>
            <div class="mt-2 font-weight-bold error--text">
              Cha mẹ học sinh lưu ý: Nhà trường chỉ tiếp nhận hồ sơ trúng tuyển trong 1 ngày duy nhất. Nếu cha mẹ học
              sinh không nộp hồ sơ trong ngày 16/04/2023, nhà trường xin phép từ chối nhập học cho học sinh.
            </div>
          </div> -->
        </div>
        <div class="py-6" v-else-if="document.ltvExamResult.passExamType === 'fail'">
          <div class="mx-auto mb-6 text-center fail-notify-card pa-4" style="max-width: 420px">
            <div>Chưa đủ điểm chuẩn</div>
          </div>
          <!-- <div class="fail-card pa-4">
            <div>
              Nhà trường rất tiếc khi thông báo học sinh chưa đạt đủ điểm chuẩn trong kỳ KSĐGNL vào lớp 6 năm hoc
              2024 - 2025.
            </div>
            <div class="mt-2">
              Nhà trường xin trân trọng cảm ơn cha mẹ học sinh đã tin tưởng và cho con tham gia kỳ KSĐGNL vào lớp 6.
            </div>
            <div>
              <div class="mt-2">
                Để biết thêm chi tiết, xin vui lòng liên hệ văn phòng nhà trường tại 2 cơ sở.
              </div>
              <div class="mt-2">Thông tin liên hệ:</div>
              <div class="mt-2"><b>Cơ Sở A:</b> Số 35 Đinh Núp, Trung Hòa, Cầu Giấy, Hà Nội</div>
              <div class="mt-2">
                SĐT: <span class="primary--text">0242.215.5985</span> –
                <span class="primary--text">0246.663.8338</span>
              </div>
              <div class="mt-2"><b>Cơ Sở I:</b> Tân Triều, Thanh Trì, Hà Nội</div>
              <div class="mt-2">SĐT: <span class="primary--text">0243.568.2603</span></div>
            </div>
          </div> -->
        </div>
        <div class="py-6" v-else-if="document.ltvExamResult.passExamType === 'reserve'">
          <div class="mx-auto mb-6 text-center fail-notify-card pa-4" style="max-width: 420px">
            <div>Trong danh sách dự bị</div>
          </div>
          <!-- <div class="fail-card pa-4">
            <div>
              <div style="line-height: 24px !important">
                Nhà trường trân trọng thông báo, học sinh đang thuộc danh sách dự bị. Nhà trường sẽ thông báo lịch trình
                tuyển sinh danh sách dự bị vào lúc
                <span class="font-weight-bold error--text">18:00 ngày 16/04/2023</span> (Nếu nhà trường còn chỉ tiêu).
              </div>
              <div class="mt-2">
                Nếu có nguyện vọng gửi con theo học tại trường, cha mẹ học sinh vui lòng để ý
                <span class="font-weight-bold"
                  >tin nhắn thông báo qua hình thức SMS đến số điện thoại đăng ký hồ sơ.</span
                >
              </div>
              <div class="mt-2">
                Xin trân trọng cảm ơn cha mẹ học sinh đã tin tưởng vào hệ thống giáo dục của nhà trường.
              </div>
              <div class="mt-2">Thông tin liên hệ:</div>
              <div class="mt-2"><b>Cơ Sở A:</b> Số 35 Đinh Núp, Trung Hòa, Cầu Giấy, Hà Nội</div>
              <div class="mt-2">
                SĐT: <span class="primary--text">0242.215.5985</span> –
                <span class="primary--text">0246.663.8338</span>
              </div>
              <div class="mt-2"><b>Cơ Sở I:</b> Tân Triều, Thanh Trì, Hà Nội</div>
              <div class="mt-2">SĐT: <span class="primary--text">0243.568.2603</span></div>
            </div>
          </div> -->
        </div>
        <div class="py-6" v-else-if="document.ltvExamResult.passExamType === 'violation'">
          <div class="mx-auto mb-6 text-center fail-notify-card pa-4" style="max-width: 420px">
            <div>Vi phạm quy chế thi</div>
          </div>
          <div class="fail-card pa-4">
            <div>
              <div>
                Học sinh <b>{{ document.name || '' }}</b> đã vi phạm quy chế thi trong thời gian làm bài kiểm tra đánh
                giá năng lực.
              </div>
              <div class="mt-2">Thông tin liên hệ:</div>
              <div class="mt-2"><b>Cơ Sở A:</b> Số 35 Đinh Núp, Trung Hòa, Cầu Giấy, Hà Nội</div>
              <div class="mt-2">
                SĐT: <span class="primary--text">0242.215.5985</span> –
                <span class="primary--text">0246.663.8338</span>
              </div>
              <div class="mt-2"><b>Cơ Sở I:</b> Tân Triều, Thanh Trì, Hà Nội</div>
              <div class="mt-2">SĐT: <span class="primary--text">0243.568.2603</span></div>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-form>
</template>

<script>
import {get} from 'lodash'
export default {
  props: {
    documentStep: Number,
    document: {
      type: Object,
      default: () => {}
    },
    systemTime: Object,
    isAdminPreview: Boolean
  },
  computed: {
    isDevelopmentMode() {
      return process.env.NODE_ENV === 'development'
    },
    isOpenDisplayResult() {
      if (
        this.document.ltvExamResult &&
        this.document.ltvExamResult.passExamText &&
        this.systemTime &&
        this.systemTime.checkDocumentSystemTime &&
        this.systemTime.checkDocumentSystemTime['display-exam-result']
      )
        return true
      return false
    },
    getCodeCV() {
      return get(this.document, 'code', 'Chưa có thông tin')
    },
    getExamMark() {
      return get(this.document, 'ltvExamResult.examMark', 'Chưa có thông tin')
    },
    getTotalMathLiterature() {
      return get(this.document, 'studyRecord.totalMathLiterature', 'Chưa có thông tin')
    },
    getPriorityMark() {
      return get(this.document, 'ltvExamResult.priorityMark', 'Chưa có thông tin')
    },
    getTotalMark() {
      // const studentProfile = Number(get(this.document, 'studyRecord.totalMathLiterature', 0))
      const priority = Number(get(this.document, 'ltvExamResult.priorityMark', 0))
      let examMath = Number(get(this.document, 'ltvExamResult.examMathMark', 0))
      let examLiterature = Number(get(this.document, 'ltvExamResult.examLiterature', 0))
      let examEnglish = Number(get(this.document, 'ltvExamResult.examEnglish', 0))
      if (isNaN(examMath)) examMath = 0
      if (isNaN(examLiterature)) examLiterature = 0
      if (isNaN(examEnglish)) examEnglish = 0
      return (examMath + examLiterature + examEnglish + priority).toFixed(2)
    },
    getExamMath() {
      const mark = get(this.document, 'ltvExamResult.examMathMark', 'Chưa có thông tin')
      if (`${mark}`.toLowerCase().includes('bo thi')) return 'Bỏ thi'
      return mark
    },
    getExamLiterature() {
      const mark = get(this.document, 'ltvExamResult.examLiterature', 'Chưa có thông tin')
      if (`${mark}`.toLowerCase().includes('bo thi')) return 'Bỏ thi'
      return mark
    },
    getExamEnglish() {
      const mark = get(this.document, 'ltvExamResult.examEnglish', 'Chưa có thông tin')
      if (`${mark}`.toLowerCase().includes('bo thi')) return 'Bỏ thi'
      return mark
    }
  },
  data() {
    return {
      isValid: false,
      agree: false
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    getData() {
      return {
        agree: this.agree
      }
    }
  }
}
</script>

<style scoped>
.field-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #797979;
  margin-bottom: 4px;
}
.section-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #3e3e3c;
}
.info-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3e3e3c;
}
.info-label__title {
  color: #000;
  min-width: 120px;
}
.info-label__data {
  font-size: 20px;
}
hr.dashed {
  width: 100%;
  border: 1px dashed #e6e4eb;
}
.card-title {
  padding: 0 !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}
.card-subtitle {
  padding: 0 !important;
  margin-top: 0 !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #3e3e3c;
}
.fail-card {
  background: rgba(255, 196, 16, 0.2);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #3e3e3c;
  border-radius: 8px;
}
.fail-notify-card {
  background: rgba(255, 29, 48, 0.2);
  color: rgba(255, 29, 48, 1);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border-radius: 8px;
}
</style>
