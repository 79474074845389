var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","width":"1024"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Soạn thông báo cho app")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Tiêu đề *","persistent-hint":"","required":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"dense":"","outlined":"","label":"Nội dung ngắn","persistent-hint":"","required":"","hint":"Hiển thị trên tin nhắn ngoài màn hình chờ"},model:{value:(_vm.shortContent),callback:function ($$v) {_vm.shortContent=$$v},expression:"shortContent"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"dense":"","outlined":"","label":"Nội dung","required":""},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.listReceivers,"item-text":"title","item-value":"value","label":"Gửi đến","required":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.receiver),callback:function ($$v) {_vm.receiver=$$v},expression:"receiver"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":[
                  {
                    title: 'Màn hình thông báo',
                    value: '/'
                  },
                  {
                    title: 'Màn hình hướng dẫn tuyển sinh',
                    value: '/info/instruction'
                  },
                  {
                    title: 'Màn hình thông tin tuyển sinh khối 6',
                    value: '/info/ts6'
                  },
                  {
                    title: 'Màn hình thông tin tuyển sinh khối 10',
                    value: '/info/ts10'
                  }
                ],"item-text":"title","item-value":"value","label":"Link","required":"","outlined":"","dense":"","hide-details":"","hint":"Đường dẫn khi nhấn vào thông báo trong app"},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}})],1),(_vm.receiver === 'single')?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Tài khoản*","required":""},model:{value:(_vm.phoneReceiver),callback:function ($$v) {_vm.phoneReceiver=$$v},expression:"phoneReceiver"}})],1):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","outlined":"","variant":"text"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Huỷ ")]),_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary","outlined":"","variant":"text"},on:{"click":_vm.onConfirm}},[_vm._v(" Xác nhận gửi ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }