<template>
  <div class="document-container d-flex py-8 mx-auto">
    <v-row no-gutters style="max-width: 100%">
      <v-col
        class="px-8"
        :class="{'pb-6': $vuetify.breakpoint.smAndDown}"
        style="border-right: 1px solid #e6e4eb"
        cols="12"
        xs="12"
        sm="12"
        md="4"
      >
        <v-stepper class="elevation-0 pt-0" v-model="step" vertical non-linear>
          <v-stepper-step
            class="mb-4 cursor-pointer"
            step="1"
            :complete="isCompleted(1)"
            @click="onStepClick('choose-department', 1)"
          >
            <span class="step-title mb-1">Chọn cơ sở</span>
            <div class="step-subtitle" :class="getStatusColor('choose-department', 1)">
              {{ getStatus('choose-department', 1) }}
            </div></v-stepper-step
          >
          <v-stepper-step
            class="mb-4 cursor-pointer"
            step="2"
            :complete="isCompleted(2)"
            @click="onStepClick('fill-info', 2)"
          >
            <span class="step-title mb-1">Thông tin phụ huynh, học sinh</span>
            <div class="step-subtitle" :class="getStatusColor('fill-info', 2)">
              {{ getStatus('fill-info', 2) }}
            </div></v-stepper-step
          >
          <v-stepper-step
            class="mb-4 cursor-pointer"
            step="3"
            :complete="isCompleted(3)"
            @click="onStepClick('study-result', 3)"
          >
            <span class="step-title mb-1">Kết quả học tập</span>
            <div class="step-subtitle" :class="getStatusColor('study-result', 3)">
              {{ getStatus('study-result', 3) }}
            </div>
          </v-stepper-step>
          <v-stepper-step
            class="cursor-pointer"
            step="4"
            :complete="isCompleted(4)"
            @click="onStepClick('exam-result', 4)"
          >
            <span class="step-title">Kết quả khảo sát năng lực</span>
            <div class="step-subtitle" :class="getStatusColor('exam-result', 4)">
              {{ getStatus('exam-result', 4) }}
            </div>
          </v-stepper-step>
        </v-stepper>
      </v-col>
      <v-col class="d-flex px-8" cols="12" xs="12" sm="12" md="8">
        <ChooseFacility
          v-if="step === 1"
          :isAdminPreview="true"
          :document="document"
          :documentStep="4"
          :isUpdatable="isUpdatable"
          @nextStep="nextStep"
          @saveDraft="saveDraft"
        />
        <InfoForm
          v-if="step === 2"
          :isAdminPreview="true"
          :document="document"
          :documentStep="4"
          :isUpdatable="isUpdatable"
          @nextStep="nextStep"
          @saveDraft="saveDraft"
        />
        <ResultForm
          v-if="step === 3"
          :isAdminPreview="true"
          :document="document"
          :documentStep="4"
          :isUpdatable="isUpdatable"
          @nextStep="nextStep"
          @saveDraft="saveDraft"
        />
        <ExamResultForm
          v-if="step === 4"
          :isAdminPreview="true"
          :document="document"
          :documentStep="4"
          :systemTime="systemTime"
          @nextStep="nextStep"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ChooseFacility from '@/views/document/ChooseFacility.vue'
import InfoForm from '@/views/document/InfoForm.vue'
import ResultForm from '@/views/document/ResultForm.vue'
import ExamResultForm from '@/views/document/ExamResultForm.vue'
export default {
  components: {
    ChooseFacility,
    InfoForm,
    ResultForm,
    ExamResultForm
  },
  computed: {
    ...mapGetters('auth', ['user', 'isAuthenticated']),
    ...mapGetters('cv', ['step']),
    isExamResultPublic() {
      return (
        this.document &&
        this.document.ltvExamResult &&
        this.document.ltvExamResult.passExamType &&
        this.document.ltvExamResult.passExamType.length > 0
      )
    },
    isUpdatable() {
      return this.$helpers.ALLOWED_UPDATE.includes(this.user.id)
    }
  },
  props: {
    document: Object,
    systemTime: Object
  },
  // created() {
  //   this.setStep(1)
  // },
  methods: {
    ...mapActions('cv', ['fetchCVs', 'fetchCV', 'updateCV', 'checkSystemTime', 'setStep']),
    ...mapActions('layout', ['setDocumentDialog']),
    getStatus(key, step) {
      if (step == 2) {
        const isStep2 = this.$utils.get(this.document, `data.step_2`, false)
        const isStep3 = this.$utils.get(this.document, `data.step_3`, false)
        let result = ''
        if (isStep2) result += 'Đã khai PH'
        else result += 'Đang khai PH'
        if (isStep3) result += ' | Đã khai HS'
        else result += ' | Đang khai HS'
        return result
      }
      if (this.$utils.get(this.document, `data.step_${step}`, false)) return 'Đã khai'
      return 'Đang khai'
    },
    getStatusColor(key, step) {
      if (step == 2) {
        const isStep2 = this.$utils.get(this.document, `data.step_2`, false)
        const isStep3 = this.$utils.get(this.document, `data.step_3`, false)
        if (isStep2 && isStep3) return 'success--text'
        return 'dark-gray--text'
      }
      if (this.$utils.get(this.document, `data.step_${step}`, false) == false) return 'dark-gray--text'
      return 'success--text'
    },
    isCompleted(step) {
      if (step == 2) {
        const isStep2 = this.$utils.get(this.document, `data.step_2`, false)
        const isStep3 = this.$utils.get(this.document, `data.step_3`, false)
        return isStep2 && isStep3
      }
      return this.$utils.get(this.document, `data.step_${step}`, false)
    },
    onStepClick(key, step) {
      if (
        (!this.systemTime.checkDocumentSystemTime || !this.systemTime.checkDocumentSystemTime[key]) &&
        this.user.role.type !== 'admin'
      ) {
        if (step === 4)
          this.$alert.error(
            'Kết quả khảo sát năng lực sẽ được thông báo đến quý phụ huynh sau khi nhà trường hoàn thành chấm điểm và đánh giá. Hình thức thông báo: \n- SMS đến số điện thoại đăng ký\n- Tại mục Kết quả khảo sát năng lực'
          )
        else
          this.$alert.error(
            'Phần thông tin tiếp theo chưa được mở. Thời gian cụ thể Nhà trường sẽ gửi qua SMS. Phụ huynh vui lòng kiểm tra tin nhắn để nhận thông báo'
          )
        return
      }
      if (this.document.step < step && this.user.role.type !== 'admin') {
        this.$alert.error('Xin vui lòng hoàn thành bước trước')
        return
      }
      this.setStep(step)
    },
    nextStep() {
      this.setStep(this.step + 1)
      this.$loading.active = false
    },
    async saveDraft({callback, ...data}) {
      if (this.isUpdatable) {
        await this.updateCV({
          submitType: 'update-admin',
          code: this.document.code,
          userPhone: this.document.parent.username,
          ...data
        })
        if (typeof callback === 'function') callback()
      } else {
        this.$alert.error('Tài khoản của bạn không được lưu thông tin này')
      }
    }
  }
}
</script>

<style scoped>
.v-stepper--vertical {
  padding: 24px 0px;
}
div >>> .v-stepper--vertical .v-stepper__step__step {
  margin-right: 24px !important;
}
.v-stepper--vertical .v-stepper__step {
  padding: 0px !important;
}
.notice {
  background: rgba(255, 196, 16, 0.2);
  border-radius: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.document-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}
.document-subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #9c9c9c;
}
.step-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.step-subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}
.document-container {
  height: 100%;
  max-width: 1280px;
}
div >>> .v-stepper__step--active .v-stepper__label {
  text-shadow: none !important;
}
div >>> .v-stepper__step--active .v-stepper__label span.step-title {
  color: #0084ff;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
