var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PrintActiveCode',{staticClass:"d-none",attrs:{"id":"printActiveCode","activeCode":_vm.selectedActiveCode}}),_c('ConfirmPrintDialog',{attrs:{"activeCode":_vm.selectedActiveCode,"state":_vm.confirmDialog},on:{"closeDialog":function($event){_vm.confirmDialog = false},"onPrint":_vm.onPrint}}),_c('v-data-table',_vm._b({attrs:{"item-key":"id","headers":_vm.headers,"loading":_vm.loading,"items":_vm.activeCodes,"items-per-page":10,"disable-sort":_vm.$vuetify.breakpoint.smAndDown,"footer-props":{ 'items-per-page-text': 'Số Mã hồ sơ một trang' }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(items){return [_vm._v(" Mã hồ sơ thứ "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" trên tổng "+_vm._s(items.itemsLength)+" Mã hồ sơ ")]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getCode")(item))+" ")]}},{key:"item.grade",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getGrade")(item))+" ")]}},{key:"item.department",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getDepartment")(item))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCreatedAt(item))+" ")]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getCreatedBy")(item))+" ")]}},{key:"item.printNum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getPrintNum")(item))+" ")]}},{key:"item.activeDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getActiveAt(item))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text d-flex justify-center",staticStyle:{"width":"105px"},attrs:{"small":"","color":_vm.getColor(item.status),"label":""}},[_vm._v(" "+_vm._s(_vm._f("getStatus")(item.status))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"color":"admin","icon":""},on:{"click":function($event){return _vm.onConfirmPrint(item)}}},[_c('v-icon',[_vm._v(" mdi-printer ")])],1),(_vm.user.department === 'both' || _vm.user.username === 'annvh')?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.status === 'disabled'),expression:"item.status === 'disabled'"}],attrs:{"color":"admin","icon":""},on:{"click":function($event){return _vm.onActiveItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-check ")])],1)]}}],null,true)},[_c('span',[_vm._v("Bật mã hồ sơ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.status !== 'disabled'),expression:"item.status !== 'disabled'"}],attrs:{"color":"admin","icon":""},on:{"click":function($event){return _vm.onDeactiveItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v("Vô hiệu hoá Mã hồ sơ")])])],1):_vm._e()],1)]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"d-flex flex-column align-center justify-center pa-6"},[_c('div',{staticClass:"mt-4 text-subtitle-1"},[_vm._v("Chưa có dữ liệu")])])]},proxy:true}],null,true)},'v-data-table',this.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }