<template>
  <v-dialog v-model="dialog" max-width="1200px" persistent>
    <ConfirmDialog />
    <v-card>
      <v-card-title class="admin white--text text-uppercase dialog-title">
        Xác nhận cập nhật điểm thi thử khối 10 lần 4
        <v-spacer></v-spacer>
        <v-btn dark icon>
          <v-icon @click="cancel" class="mr-n1" :disabled="loading">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-6">
        <v-data-table
          item-key="code"
          :headers="[
            {text: 'Mã hồ sơ', value: 'code'},
            {text: 'Toán', value: 'testMath'},
            {text: 'Văn', value: 'testLiterature'},
            {text: 'Anh', value: 'testEnglish'},
            {text: 'Tổng điểm', value: 'testTotalMark'}
          ]"
          :loading="loading"
          :items="importedDocuments"
          :items-per-page="10"
          :disable-sort="$vuetify.breakpoint.smAndDown"
          :footer-props="{'items-per-page-text': 'Số Hồ Sơ một trang'}"
        >
        </v-data-table>
        <v-row>
          <v-col cols="12" v-if="importState.length > 0">
            <div v-html="importState"></div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-btn color="admin" @click="cancel" width="118" :loading="loading" large outlined>Hủy</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="admin" @click="confirm" :loading="loading" width="118" class="white--text elevation-0" large
          >Xác nhận</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import ConfirmDialog from '@/components/plugin/PluginAdminConfirmDialog'
import {mapActions, mapGetters} from 'vuex'
import {get} from 'lodash'
export default {
  components: {
    ConfirmDialog
  },
  props: {
    importedDocuments: Array,
    state: Boolean
  },
  watch: {
    state(state) {
      this.dialog = state
      if (!state) this.loading = false
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      importState: ''
    }
  },
  computed: {
    ...mapGetters('auth', ['user'])
  },
  methods: {
    ...mapActions('cv', ['fetchCVs', 'fetchCV', 'updateCV']),
    cancel() {
      this.$emit('closeDialog')
    },
    async confirm() {
      this.$adminDialog.confirm({
        title: 'Xác nhận cập nhật kết quả thi thử',
        okText: 'Xác nhận',
        topContent: `Hãy kiểm tra thật kỹ kết quả thi thử của các thí sinh.`,
        midContent: `Sau khi nhấn "Xác nhận" kết quả sẽ được <span class="error--text">hiển thị cho tất cả thí sinh</span>`,
        botContent: 'Nếu đã chắc chắn quý phụ huynh bấm vào nút xác nhận bên dưới để tiếp tục',
        cancelText: 'Hủy',
        done: async () => {
          this.loading = true
          this.updateTestExamResult(this.importedDocuments)
        }
      })
    },
    async updateTestExamResult(results) {
      try {
        const promises = results.map(async result => {
          const existingCV = await this.fetchCV({
            code: result.code,
            type: 'Khối 10'
          })
          if (!existingCV) return
          let query = {
            code: result.code,
            submitType: 'update-admin',
            ltvExamResult: {
              ...get(existingCV, 'ltvExamResult', {}),
              testMath: result.testMath,
              testLiterature: result.testLiterature,
              testEnglish: result.testEnglish,
              testTotalMark: result.testTotalMark
            },
            data: {
              ...get(existingCV, 'data', {}),
              isTestExamShown: true,
            },
            userPhone: this.user.username,
            isDraft: false
          }
          await this.updateCV(query)
        })
        await Promise.all(promises)
      } catch (error) {
        this.$alert.error(error)
      }
      this.loading = false
      this.$alert.success('Đã cập nhật thông tin điểm thi thử của tất cả thí sinh thành công!')
    }
  }
}
</script>
