<template>
  <v-dialog v-model="dialog" max-width="1200px" persistent>
    <ConfirmDialog />
    <v-card>
      <v-card-title class="admin white--text text-uppercase dialog-title">
        Xác nhận gửi tin cho phụ huynh
        <v-spacer></v-spacer>
        <v-btn dark icon>
          <v-icon @click="cancel" class="mr-n1" :disabled="loading">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-6">
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field v-model="title" dense outlined label="Tiêu đề *" persistent-hint required></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="shortContent"
                dense
                outlined
                label="Nội dung ngắn"
                persistent-hint
                required
                hint="Hiển thị trên tin nhắn ngoài màn hình chờ"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="content"
                dense
                outlined
                label="Nội dung"
                required
                hint="Giữ nguyên 'studentName', 'sbd', 'ranking'"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                :items="[
                  {
                    title: 'Màn hình thông báo',
                    value: '/'
                  },
                  {
                    title: 'Màn hình danh sách hồ sơ',
                    value: '/cv'
                  }
                ]"
                item-text="title"
                item-value="value"
                v-model="link"
                label="Link"
                required
                outlined
                dense
                hide-details
                hint="Đường dẫn khi nhấn vào thông báo trong app"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
        <v-data-table
          item-key="username"
          :headers="[
            {text: 'Tài khoản', value: 'username'},
            {text: 'SBD', value: 'sbd'},
            {text: 'Tên học sinh', value: 'studentName'},
            {text: 'Xếp hạng (áp dụng với DỰ KHUYẾT)', value: 'ranking'}
          ]"
          :loading="loading"
          :items="importedDocuments"
          :items-per-page="10"
          :disable-sort="$vuetify.breakpoint.smAndDown"
          :footer-props="{'items-per-page-text': 'Số Hồ Sơ một trang'}"
        >
        </v-data-table>
        <v-row>
          <v-col cols="12" v-if="importState.length > 0">
            <div v-html="importState"></div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-btn color="admin" @click="cancel" width="118" :loading="loading" large outlined>Hủy</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="admin" @click="confirm" :loading="loading" width="118" class="white--text elevation-0" large
          >Xác nhận</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import ConfirmDialog from '@/components/plugin/PluginAdminConfirmDialog'
import {mapActions} from 'vuex'
export default {
  components: {
    ConfirmDialog
  },
  props: {
    importedDocuments: Array,
    state: Boolean
  },
  watch: {
    state(state) {
      this.dialog = state
      if (!state) this.loading = false
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      title: 'Thông báo kết quả tuyển sinh',
      shortContent: 'Kết quả tuyển sinh của học sinh',
      content: 'Học sinh {{studentName}} có SBD là {{sbd}}, xếp hạng {{ranking}}',
      link: '/',
      importState: ''
    }
  },
  methods: {
    ...mapActions({
      sendNotification: 'notification/createNoti'
    }),
    cancel() {
      this.$emit('closeDialog')
    },
    async confirm() {
      this.$adminDialog.confirm({
        title: 'Xác nhận gửi tin',
        okText: 'Xác nhận',
        topContent: `Hãy kiểm tra thật kỹ kết quả tuyển sinh của các thí sinh.`,
        midContent: `Sau khi nhấn "Xác nhận" thông tin kết quả tuyển sinh <span class="error--text">hiển thị cho tất cả thí sinh</span>`,
        botContent: 'Nếu đã chắc chắn quý phụ huynh bấm vào nút xác nhận bên dưới để tiếp tục',
        cancelText: 'Hủy',
        done: async () => {
          this.loading = true
          await this.bulkSend()
          this.loading = false
        }
      })
    },
    async bulkSend() {
      for (let i = 0; i < this.importedDocuments.length; i++) {
        const data = this.importedDocuments[i]
        let content = this.content
        content = content.replace('{{studentName}}', data.studentName)
        content = content.replace('{{sbd}}', data.sbd)
        content = content.replace('{{ranking}}', data.ranking)
        await this.onSend(content, data.username)
        this.importState += `(${i + 1}/${this.importedDocuments.length}) Đã gửi cho ${data.username} <br>`
      }
    },
    async onSend(content = '', phoneReceiver = '') {
      try {
        await this.sendNotification({
          title: this.title,
          shortContent: this.shortContent,
          content,
          phoneReceiver,
          status: 'success',
          method: 'app',
          receiver: 'single',
          data: {
            link: this.link
          }
        })
        this.$alert.success('Tạo thông báo thành công!')
      } catch (error) {
        this.$alert.error(error)
        console.log('e', error)
      }
    }
  }
}
</script>
