<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent width="1024">
      <v-card>
        <v-card-title>
          <span class="text-h5">Soạn thông báo cho app</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="title" dense outlined label="Tiêu đề *" persistent-hint required></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="shortContent"
                  dense
                  outlined
                  label="Nội dung ngắn"
                  persistent-hint
                  required
                  hint="Hiển thị trên tin nhắn ngoài màn hình chờ"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea v-model="content" dense outlined label="Nội dung" required></v-textarea>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  :items="listReceivers"
                  item-text="title"
                  item-value="value"
                  v-model="receiver"
                  label="Gửi đến"
                  required
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  :items="[
                    {
                      title: 'Màn hình thông báo',
                      value: '/'
                    },
                    {
                      title: 'Màn hình hướng dẫn tuyển sinh',
                      value: '/info/instruction'
                    },
                    {
                      title: 'Màn hình thông tin tuyển sinh khối 6',
                      value: '/info/ts6'
                    },
                    {
                      title: 'Màn hình thông tin tuyển sinh khối 10',
                      value: '/info/ts10'
                    }
                  ]"
                  item-text="title"
                  item-value="value"
                  v-model="link"
                  label="Link"
                  required
                  outlined
                  dense
                  hide-details
                  hint="Đường dẫn khi nhấn vào thông báo trong app"
                ></v-select>
              </v-col>
              <v-col v-if="receiver === 'single'" cols="12">
                <v-text-field dense outlined label="Tài khoản*" v-model="phoneReceiver" required></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" outlined variant="text" @click="dialog = false">
            Huỷ
          </v-btn>
          <v-btn :loading="loading" color="primary" outlined variant="text" @click="onConfirm">
            Xác nhận gửi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import {mapActions} from 'vuex'
export default {
  data: () => ({
    title: '',
    content: '',
    shortContent: '',
    dialog: false,
    loading: false,
    receiver: 'all',
    link: '/',
    phoneReceiver: '',
    listReceivers: [
      {
        title: 'Tất cả',
        value: 'all'
      },
      {
        title: 'Những người đã đăng ký app',
        value: 'reg'
      },
      {
        title: 'Những người đã mua hồ sơ khối 6',
        value: 'reg_cv6'
      },
      {
        title: 'Những người đã mua hồ sơ khối 10',
        value: 'reg_cv10'
      },
      {
        title: 'Những người chưa kích hoạt hồ sơ',
        value: 'reg_no_cv'
      },
      {
        title: 'Đối tượng cụ thể',
        value: 'single'
      }
    ]
  }),
  methods: {
    ...mapActions({
      sendNotification: 'notification/createNoti'
    }),
    openDialog() {
      this.dialog = true
    },
    async onConfirm() {
      if (this.receiver === 'single' && this.phoneReceiver.length < 1) {
        return this.$alert.error('Vui lòng nhập số điện thoại ứng với tài khoản')
      }
      try {
        this.loading = true
        await this.sendNotification({
          title: this.title,
          shortContent: this.shortContent,
          content: this.content,
          receiver: this.receiver,
          status: 'success',
          method: 'app',
          data: {
            link: this.link
          },
          phoneReceiver: this.phoneReceiver
        })
        this.loading = false
        this.$alert.success('Tạo thông báo thành công!')
        this.dialog = false
      } catch (error) {
        this.$alert.error(error)
        this.dialog = false
      }
    }
  }
}
</script>
