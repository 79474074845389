<template>
  <div class="pa-6">
    <div class="d-flex justify-space-between align-center mb-6">
      <div class="component-title">Danh sách thông báo</div>
      <v-btn @click="$refs['dialog'].openDialog()" outlined color="primary">+ Soạn thông báo</v-btn>
    </div>
    <div class="d-flex justify-end">
      <ReviewExcelAction class="mr-2" />
      <ResultExcelAction class="mr-2" />
      <ExcelAction />
    </div>
    <TableNotification />
    <CreateNotificationDialog ref="dialog" />
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import TableNotification from './TableNotification.vue'
import CreateNotificationDialog from './CreateNotificationDialog'
import ExcelAction from './ExcelAction.vue'
import ResultExcelAction from './excel-action/ResultExcelAction.vue'
import ReviewExcelAction from './excel-action/ReviewExcelAction.vue'
export default {
  components: {
    TableNotification,
    CreateNotificationDialog,
    ExcelAction,
    ResultExcelAction,
    ReviewExcelAction
  },
  created() {
    this.fetchNotifications({})
  },
  methods: {
    ...mapActions({
      fetchNotifications: 'notification/fetchNotis'
    })
  }
}
</script>

<style scoped>
.chip-width {
  min-width: 82px;
  justify-content: center;
}
.component-title {
  color: #0d47a1;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}
</style>
