<template>
  <div>
    <v-data-table
      item-key="id"
      :headers="headers"
      :loading="loading"
      :items="CVs"
      :items-per-page="10"
      :disable-sort="$vuetify.breakpoint.smAndDown"
      :footer-props="{ 'items-per-page-text': 'Số Hồ Sơ một trang' }"
      v-bind="this.$attrs"
    >
      <template v-slot:[`footer.page-text`]="items">
        Hồ sơ thứ {{ items.pageStart }} - {{ items.pageStop }} trên tổng
        {{ items.itemsLength }} Hồ sơ
      </template>
      <template v-slot:[`item.code`]="{ item }">
        <v-btn
          class="document-btn text-none text-decoration-underline px-0"
          color="primary"
          @click="onDocumentClick(item.id)"
          plain
        >
          {{ item | getCode }}
        </v-btn>
      </template>
      <template v-slot:[`item.department`]="{ item }">
        {{ item | getDepartment }}
      </template>
      <template v-slot:[`item.name`]="{ item }">
        {{ item | getStudent }}
      </template>
      <template v-slot:[`item.dob`]="{ item }">
        {{ item | getStudentDob }}
      </template>
      <template v-slot:[`item.testMark`]="{ item }">
        <div v-html="getTestMark(item)" class="py-2"/>
      </template>
      <template v-slot:[`item.entranceMark`]="{ item }">
        <div v-html="getEntranceMark(item)" class="py-2"/>
      </template>
      <template v-slot:[`item.groupExpectation1`]="{ item }">
        <span style="white-space:pre-line;">{{ getExpectation1(item) }}</span>
      </template>
      <template v-slot:[`item.groupExpectation2`]="{ item }">
        <span style="white-space:pre-line;">{{ getExpectation2(item) }}</span>
      </template>
      <template v-slot:[`item.examPass`]="{ item }">
        <span :class="getColor(item)">{{ item | isPassExam }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

const originHeaders = [
  {
    text: "Mã hồ sơ",
    value: "code",
    align: "left",
    sortable: false,
    show: true,
  },
  {
    text: "Cơ sở",
    value: "department",
    align: "left",
    sortable: false,
    show: true,
  },
  {
    text: "Họ và tên",
    value: "name",
    align: "left",
    sortable: false,
    show: true,
  },
  {
    text: "Ngày sinh",
    value: "dob",
    align: "left",
    sortable: false,
    show: true,
  },
  {
    text: "Điểm thi thử",
    value: "testMark",
    align: "left",
    sortable: false,
    show: true,
  },
  {
    text: "Điểm xét tuyển",
    value: "entranceMark",
    align: "left",
    sortable: false,
    show: true,
  },
  {
    text: "NV1",
    value: "groupExpectation1",
    align: "left",
    sortable: false,
    show: true,
  },
  {
    text: "NV2",
    value: "groupExpectation2",
    align: "left",
    sortable: false,
    show: true,
  },
  {
    text: "Kết quả trúng tuyển",
    value: "examPass",
    align: "left",
    sortable: false,
    show: true,
  },
];

import { mapActions, mapGetters } from "vuex";
import { get } from "lodash";
import moment from "moment";

export default {
  computed: {
    ...mapGetters("cv", ["CVs", "CV"]),
    ...mapGetters("auth", ["user"]),
  },
  data() {
    return {
      loading: false,
      headers: originHeaders,
      selectedCode: "",
      selectedCreatedAt: "",
    };
  },
  async mounted() {
    let query = {
      _sort: "updatedAt:DESC",
    };
    if (this.user.department === "both")
      query.department_in = ["Cơ sở A", "Cơ sở 1", "unset"];
    else query.department_in = [this.user.department, "unset"];
    await this.refresh(query);
  },
  methods: {
    ...mapActions("cv", ["fetchCVs", "fetchCV", "updateCV"]),
    async refresh(query) {
      this.loading = true;
      await this.fetchCVs({
        ...query,
        type: "Khối 10",
      });
      this.loading = false;
    },
    onDocumentClick(documentId) {
      this.$emit("onDocumentDetail", documentId);
    },
    search() {},
    getColor(item) {
      if (
        item.ltvExamResult &&
        item.ltvExamResult.passExam &&
        item.ltvExamResult.passExam != ""
      ) {
        if (item.ltvExamResult.passExam) return "success--text";
        else return "error--text";
      }
      return "";
    },
    getExpectation1(item) {
      const expectation = get(item, 'ltvExamResult.groupExpectation1')
      if (expectation) return this.getGroupExpectation(expectation)
      return "---";
    },
    getExpectation2(item) {
      const expectation = get(item, 'ltvExamResult.groupExpectation2')
      if (expectation) return this.getGroupExpectation(expectation)
      return "---";
    },
    getGroupExpectation(expectation) {
      let result = []
      const childKHXH = this.getGroupExpectationChildren(get(expectation, 'groupKHXH', []), 'KHXH: ')
      const childKHTN = this.getGroupExpectationChildren(get(expectation, 'groupKHTN', []), 'KHTN: ')
      const childCNNT = this.getGroupExpectationChildren(get(expectation, 'groupCNNT', []), 'CNNT: ')
      if (childKHXH.length) result.push(childKHXH)
      if (childKHTN.length) result.push(childKHTN)
      if (childCNNT.length) result.push(childCNNT)
      if (result.length) return result.join(`\n`)
      return '---'
    },
    getGroupExpectationChildren(group, prefix = '') {
      if (Array.isArray(group) && group.length) {
        return prefix + group.join(', ')
      }
      return ''
    },
    getTestMark: (item) => {
      const isParticipated = get(item, 'data.isTestExamShown', false)
      if (!isParticipated) return 'Không tham dự' 
      const testMath = get(item, 'ltvExamResult.testMath', '')
      const testEnglish = get(item, 'ltvExamResult.testEnglish', '')
      const testLiterature = get(item, 'ltvExamResult.testLiterature', '')
      const testTotalMark = get(item, 'ltvExamResult.testTotalMark', '')
      return `Toán: ${testMath}<br>Văn: ${testLiterature}<br>Anh: ${testEnglish}<br>Tổng: ${testTotalMark}`
    },
    getEntranceMark: (item) => {
      const testMath = get(item, 'ltvExamResult.examMath', '')
      const testEnglish = get(item, 'ltvExamResult.examEnglish', '')
      const testLiterature = get(item, 'ltvExamResult.examLiterature', '')
      const testTotalMark = get(item, 'ltvExamResult.examTotalMark', '')
      const testPriorityMark = get(item, 'ltvExamResult.priorityMark', '')
      return `Toán: ${testMath}<br>Văn: ${testLiterature}<br>Anh: ${testEnglish}<br>Điểm ưu tiên: ${testPriorityMark}<br>Tổng: ${testTotalMark}`
    },
  },
  filters: {
    getStudent: (item) => {
      return get(item, "name", "---");
    },
    getDepartment: (item) => {
      if (!item.department || item.department === "unset") return "---";
      else return item.department;
    },
    getCode: (item) => {
      return get(item, "code", "---");
    },
    getStudentDob: (item) => {
      if (item.dob) return moment(item.dob).format("DD/MM/YYYY");
      return "---";
    },
    isPassExam: (item) => {
      const type = get(item, "ltvExamResult.passExamType", "");
      switch(type) {
        case 'pass': return 'Đã trúng tuyển'
        case 'fail': return 'Không trúng tuyển'
        case 'reserve': return 'Dự khuyết'
        default: return '---'
      }
    },
  },
};
/* eslint-enable no-unused-vars */
</script>
