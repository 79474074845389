<template>
  <v-app>
    <v-main>
      <PluginAlert />
      <PluginConfirmDialog />
      <PluginLoading />
      <div
        class="
          text-h5 text-center
          d-flex
          flex-column
          justify-center
          align-center
          pa-6
          mx-auto
        "
        style="height: 100%; max-width: 1200px"
        v-if="isMaintainMode"
      >
        <v-icon color="red" size="80px" class="mb-6">mdi-information</v-icon>
        <div class="text-h4 font-weight-bold">
          Hệ thống đang quá tải!
        </div>
        <div class="mt-5 text-subtitle-1 text-center" style="max-width: 550px">
          Trang Web đang tạm thời bị gián đoạn. Cha mẹ học sinh có thể không truy cập được trong giây lát. Mong cha mẹ
          học sinh thông cảm và thử lại sau.
        </div>
      </div>
      <Footer v-if="isMaintainMode" />
      <MainToolbar />
      <router-view v-if="!isMaintainMode"></router-view>
      <DocumentDialog />
      <SignInDialog />
      <ForgotPasswordDialog />
      <ConfirmForgotPasswordDialog />
      <NewPasswordDialog />
      <SignUpDialog />
      <PhoneRegisterDialog />
      <ConfirmSignupDialog />
      <!-- <div id="notice" v-if="isDevelopmentBuild">
        <div class="error--text text-subtitle-1">Development Build. v{{ version }}</div>
      </div> -->
    </v-main>
  </v-app>
</template>

<script>
import MainToolbar from '@/components/layout/MainToolbar.vue'
import SignInDialog from '@/views/auth/SignInDialog.vue'
import ForgotPasswordDialog from '@/views/auth/ForgotPasswordDialog.vue'
import ConfirmForgotPasswordDialog from '@/views/auth/ConfirmForgotPasswordDialog.vue'
import NewPasswordDialog from '@/views/auth/NewPasswordDialog.vue'
import SignUpDialog from '@/views/auth/SignUpDialog.vue'
import ConfirmSignupDialog from '@/views/auth/ConfirmSignupDialog.vue'
import DocumentDialog from '@/views/document/DocumentDialog.vue'
import PluginConfirmDialog from '@/components/plugin/PluginConfirmDialog'
import PluginAlert from '@/components/plugin/PluginAlert'
import PluginLoading from '@/components/plugin/PluginLoading'
import PhoneRegisterDialog from '@/views/auth/PhoneRegisterDialog.vue'
import Footer from './Footer.vue'
import {mapGetters} from 'vuex'
export default {
  name: 'App',
  components: {
    MainToolbar,
    SignInDialog,
    ForgotPasswordDialog,
    ConfirmForgotPasswordDialog,
    NewPasswordDialog,
    SignUpDialog,
    ConfirmSignupDialog,
    DocumentDialog,
    PluginAlert,
    PluginConfirmDialog,
    PluginLoading,
    PhoneRegisterDialog,
    Footer
  },
  data() {
    return {
      isDevelopmentBuild: true,
      version: '0.1'
    }
  },
  computed: {
    ...mapGetters('auth', ['isMaintainMode'])
  },
  created() {
    this.version = process.env.VUE_APP_API_VERSION
    this.isDevelopmentBuild = process.env.NODE_ENV === 'development'
  }
}
</script>
<style>
.container.fill-height > .row {
  flex: 1 1 100%;
  max-width: calc(100%) !important;
}

.v-card--link:focus:before {
  opacity: 0 !important;
}
#notice {
  position: absolute;
  bottom: 12px;
  left: 12px;
}
</style>
