var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"980px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('div',{class:{
          'text-subtitle-1 font-weight-bold title--text':
            _vm.$vuetify.breakpoint.smAndDown,
          'title--text': _vm.$vuetify.breakpoint.mdAndUp,
        }},[_vm._v(" Quản lý hồ sơ ")]),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){return _vm.cancel()}}},[_vm._v("mdi-close")])],1),_c('v-divider'),_c('v-row',{staticClass:"pa-6",staticStyle:{"background":"#0084ff"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","xs":"12","md":"4"}},[_c('v-card',{class:{
            'mr-6': _vm.$vuetify.breakpoint.mdAndUp,
            'mb-6': _vm.$vuetify.breakpoint.smAndDown,
          }},[_c('v-card-title',{staticClass:"subtitle"},[_vm._v("Tạo hồ sơ mới")]),_c('v-divider'),_c('v-card-text',{staticClass:"pa-4",staticStyle:{"background":"#f2f0f1"}},[_c('v-form',{ref:"form"},[_c('div',{staticClass:"text-subtitle-1 mb-2"},[_vm._v(" Mã hồ sơ "),_c('span',[_c('v-icon',{staticStyle:{"font-size":"14px"},attrs:{"color":"#FF1D30"},on:{"click":function($event){return _vm.toggleTutorial(true)}}},[_vm._v("mdi-help-circle-outline")])],1)]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('############'),expression:"'############'"}],attrs:{"placeholder":"Nhập mã hồ sơ tại đây","name":"login","type":"text","color":"primary","rules":_vm.codeRules,"outlined":"","validate-on-blur":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit.apply(null, arguments)}},model:{value:(_vm.activeCode),callback:function ($$v) {_vm.activeCode=$$v},expression:"activeCode"}}),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"elevation-0 text-none btn px-6 py-3 text-center",attrs:{"color":"primary","loading":_vm.loading,"disabled":!_vm.isValidActiveCode,"large":""},on:{"click":_vm.onCreateDocument}},[_vm._v("Xác nhận")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","xs":"12","md":"8"}},[_c('v-card',[_c('DocumentTable',{ref:"documentTable"})],1)],1)],1)],1),_c('DocumentTutorial',{attrs:{"state":_vm.tutorialDialog,"src":(_vm.$baseUrl + "HuongDanTuyenSinh.html")},on:{"closeDialog":_vm.toggleTutorial}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }